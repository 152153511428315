import { ChangeEvent, useEffect, useRef } from "react";
import Modal from "../../components/Modal";
import { LayoutComponent } from "../../interfaces/LayoutComponent";
import { Select } from "antd";

type ComponentSettingsModalProps = {
  selectedComponent: LayoutComponent;
  onSelect: (key: string, e: ChangeEvent<HTMLInputElement>) => void;
  onClose: () => void;
  onAddComponent: () => void;
  onSelectChange: (value: Array<string>) => void;
  onUnitChange: (value: string) => void;
};

const ComponentSettingsModal: React.FC<ComponentSettingsModalProps> = ({
  selectedComponent,
  onSelect,
  onAddComponent,
  onClose,
  onSelectChange,
  onUnitChange,
}) => {
  const labelRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (labelRef.current) {
      labelRef.current.focus();
    }
  }, [selectedComponent]);

  return (
    <Modal
      onClose={onClose}
      title="Component Settings"
      modalContent={
        <div>
          <div className="grid grid-cols-2 gap-2 px-2 py-4">
            <div className="block">
              <label className="block text-sm font-medium leading-6 text-gray-900">
                Label
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  ref={labelRef}
                  value={selectedComponent.data.label}
                  disabled={selectedComponent.data.isVital}
                  onChange={(e) => onSelect("label", e)}
                  className="block w-full disabled:bg-gray-300 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="block">
              <label className="block text-sm font-medium leading-6 text-gray-900">
                Key
              </label>
              <div className="mt-2">
                <input
                  readOnly
                  type="text"
                  value={selectedComponent.data.key}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="block">
              <label className="block text-sm font-medium leading-6 text-gray-900">
                Width
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  value={selectedComponent?.w}
                  name="width"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  onChange={(e) => onSelect("w", e)}
                />
              </div>
            </div>
            <div className="block">
              <label className="block text-sm font-medium leading-6 text-gray-900">
                Height
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  value={selectedComponent.h}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  onChange={(e) => onSelect("h", e)}
                />
              </div>
            </div>
            <div className="block">
              <label className="block text-sm font-medium leading-6 text-gray-900">
                Min Height
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  value={selectedComponent.minH}
                  onChange={(e) => onSelect("minH", e)}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="block">
              <label className="block text-sm font-medium leading-6 text-gray-900">
                Max Height
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  value={selectedComponent.maxH}
                  onChange={(e) => onSelect("maxH", e)}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="block">
              <label className="block text-sm font-medium leading-6 text-gray-900">
                Min Width
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  value={selectedComponent.minW}
                  onChange={(e) => onSelect("minW", e)}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="block">
              <label className="block text-sm font-medium leading-6 text-gray-900">
                Max Width
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  value={selectedComponent.maxW}
                  onChange={(e) => onSelect("maxW", e)}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="block">
              <label className="block text-sm font-medium leading-6 text-gray-900">
                Configure Options
              </label>
              <div className="mt-2">
                <Select
                  mode="tags"
                  onChange={onSelectChange}
                  style={{ width: "100%" }}
                  options={selectedComponent.data.options}
                />
              </div>
            </div>
            {selectedComponent.data.isVital && (
              <div className="block">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Select Default Unit
                </label>
                <div className="mt-2">
                  <Select
                    onChange={onUnitChange}
                    style={{ width: "100%" }}
                    options={selectedComponent.data.units
                      .split(",")
                      .map((i: string) => ({ label: i, value: i }))}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="flex items-center justify-end py-4 gap-x-6 border-gray-900/10 ">
            <button
              onClick={() => onClose()}
              type="button"
              className="text-sm font-semibold leading-6 text-gray-900"
            >
              Cancel
            </button>
            <button
              onClick={() => onAddComponent()}
              type="submit"
              className="px-3 py-2 text-sm font-semibold text-white rounded-md shadow-sm bg-primary hover:bg-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-dark"
            >
              Update Component
            </button>
          </div>
        </div>
      }
    />
  );
};

export default ComponentSettingsModal;
