import { useParams } from "react-router-dom";
import AddVisit from "./AddVisit";
import PreviousVisitCard from "./PreviousVisitCard";
import { useEffect, useState } from "react";
import { getPreviousVisits, getVisit, getVisitType } from "../../apis/visit";
import { Visit } from "../../interfaces/Visit";
import { format } from "date-fns";
import { useAppDispatch } from "../../hooks";
import { changeVisit } from "../../features/visit/visitSlice";
import { getPatient } from "../../apis/patient";

const VisitTimeline = () => {
  const [previousVisits, setPreviousVisit] = useState<Array<Visit>>([]);
  const [visit, setVisit] = useState<Visit>();
  const [patient, setPatient] = useState<any>(null);
  const [visitType, setVisitType] = useState<any>(null);
  const params = useParams();
  const currentDate = new Date();
  const formattedDate = format(currentDate, "yyyy-MM-dd");
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (params.visitId) {
      fetchVisit(params.visitId);
    }
    if (params.patientId) {
      fetchPatient(params.patientId);
    }
    if (params.typeId) {
      fetchVisitType(params.typeId);
    }
  }, []);

  const fetchPatient = (patientId: any) => {
    getPatient(patientId).then((res: any) => {
      setPatient(res.data.data);
    });
  };

  const fetchPreviousVisit = (id: any) => {
    getPreviousVisits(id).then((res: any) => {
      setPreviousVisit(res.data.data);
    });
  };

  const fetchVisit = async (id: any) => {
    await getVisit(id).then(async (res: any) => {
      dispatch(changeVisit(res));
      setVisit(res);
      setPatient(res.patient);
      if (res.parentId) {
        fetchPreviousVisit(res.parentId);
      }
    });
  };

  const fetchVisitType = (id: any) => {
    getVisitType(id).then((res: any) => {
      setVisitType(res.data);
    });
  };

  return (
    <div className="flex flex-col flex-grow">
      <main className="flex flex-col flex-grow bg-[#ECEFFF] rounded-md">
        <div className="p-2">
          <ol className="relative border-l-2 border-primary">
            <li className="mb-4 ml-4">
              <div className="absolute w-4 h-4 border border-white rounded-full bg-primary -left-2 "></div>
              <time className="pb-2 text-sm font-normal leading-none text-active dark:text-gray-500">
                Patient Info
              </time>
              <div className="grid grid-cols-3 gap-4 p-4 mb-5 bg-white rounded">
                <div className="flex w-full">
                  <span className="font-medium text-gray-900">
                    Patient Name:{" "}
                  </span>
                  <span className="ml-2 text-gray-800">
                    {patient?.firstName} {patient?.lastName}
                  </span>
                </div>
                <div className="flex w-full">
                  <span className="font-medium text-gray-900">Reg. No.: </span>
                  <span className="ml-2 text-gray-800">
                    {patient?.registrationNumber}
                  </span>
                </div>
                <div className="flex w-full">
                  <span className="font-medium text-gray-900">Phone: </span>
                  <span className="ml-2 text-gray-800">{patient?.phone}</span>
                </div>
                <div className="flex w-full">
                  <span className="font-medium text-gray-900">
                    Visit Type:{" "}
                  </span>
                  <span className="ml-2 text-gray-800 capitalize">
                    {visit?.clinicVisitType?.visitType.name
                      ? visit.clinicVisitType.visitType.name
                      : visitType?.name}
                  </span>
                </div>
              </div>
            </li>
            <li className="mb-4 ml-4">
              <div className="absolute w-4 h-4 bg-primary rounded-full mt-1.5 -left-2 border border-white "></div>
              <time className="mb-1 text-sm font-normal leading-none text-active dark:text-gray-500">
                {formattedDate}
              </time>
              <AddVisit />
            </li>

            {previousVisits &&
              previousVisits.length > 0 &&
              previousVisits.map((i, index: number) => (
                <li className="ml-4" key={index}>
                  <PreviousVisitCard key={i.id} previousVisit={i} />
                </li>
              ))}
          </ol>
        </div>
      </main>
    </div>
  );
};

export default VisitTimeline;
