import React, { FC, useEffect, useState } from "react";
import { AmsaInputProps } from "../interfaces/AmsaInputs";
function classNames(...classes: Array<string>) {
  return classes.filter(Boolean).join(" ");
}
const FasciaChart: FC<AmsaInputProps> = ({
  data,
  setValue,
}: AmsaInputProps) => {
  // Handle cell click
  const handleCellClickOld = (rowIndex: any, colIndex: any) => {
    if (data.value[rowIndex][colIndex].isClickable) {
      const newData = [...data.value];
      newData[rowIndex][colIndex].content = (
        (Number(newData[rowIndex][colIndex].content) + 1) %
        4
      ).toString();
      setValue(newData);
    }
  };

  const handleCellClick = (rowIndex: any, colIndex: any) => {
    if (!data.value[rowIndex][colIndex].isClickable) {
      return;
    }
    const value = (
      (Number(data.value[rowIndex][colIndex].content) + 1) %
      4
    ).toString();
    if (data.value[rowIndex] && data.value[rowIndex][colIndex]) {
      // Step 1: Create a copy of the object with the updated property
      const updatedObject = {
        ...data.value[rowIndex][colIndex],
        content: value,
      };

      // Step 2: Create a new row array with the updated object
      const updatedRow = [
        ...data.value[rowIndex].slice(0, colIndex),
        updatedObject,
        ...data.value[rowIndex].slice(colIndex + 1),
      ];

      // Step 3: Create a new array with the updated row
      const updatedData = [
        ...data.value.slice(0, rowIndex),
        updatedRow,
        ...data.value.slice(rowIndex + 1),
      ];

      setValue(updatedData); // Update the original newData with the new array
      data.value = updatedData;
    } else {
      console.error("Invalid row or column indexi");
    }
  };

  const handleCellInputClickOld = (
    rowIndex: any,
    colIndex: any,
    value: string
  ) => {
    if (data.value[rowIndex][colIndex].isInput) {
      const newData = [...data.value];
      newData[rowIndex][colIndex] = {
        ...newData[rowIndex][colIndex],
        content: value,
      };
      // setdata.value(newData);
      setValue(newData);
    }
  };

  const handleCellInputClick = (
    rowIndex: any,
    colIndex: any,
    value: string
  ) => {
    if (data.value[rowIndex] && data.value[rowIndex][colIndex]) {
      // Step 1: Create a copy of the object with the updated property
      const updatedObject = {
        ...data.value[rowIndex][colIndex],
        content: value,
      };

      // Step 2: Create a new row array with the updated object
      const updatedRow = [
        ...data.value[rowIndex].slice(0, colIndex),
        updatedObject,
        ...data.value[rowIndex].slice(colIndex + 1),
      ];

      // Step 3: Create a new array with the updated row
      const updatedData = [
        ...data.value.slice(0, rowIndex),
        updatedRow,
        ...data.value.slice(rowIndex + 1),
      ];

      data.value = updatedData;
      setValue(updatedData); // Update the original newData with the new array
    } else {
      console.error("Invalid row or column index");
    }
  };

  // Function to render stars based on the count
  const renderStars = (count: any) => {
    return "★".repeat(count);
  };

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full border border-collapse table-fixed border-slate-400">
        <tbody className="bg-white divide-y divide-gray-200">
          {data.value.map((row: Array<any>, rowIndex: number) => (
            <tr key={rowIndex}>
              {row.map((cell, colIndex) => (
                <td
                  key={colIndex}
                  colSpan={cell.colspan}
                  rowSpan={cell.rowspan}
                  className={classNames(
                    cell.alignment ? cell.alignment : "",
                    cell.isInput || cell.isClickable ? "cursor-pointer" : "",
                    `h-6 px-2 border border-gray-300 `,
                    cell.isVerticalText ? "vertical-text" : ""
                  )}
                  style={{ width: "100px" }}
                  onClick={() => handleCellClick(rowIndex, colIndex)}
                >
                  <div
                    className={classNames(
                      cell.alignment ? " justify-center items-center " : "",
                      "flex  w-full h-full"
                    )}
                  >
                    <div>{cell.label}</div>
                    <div>{cell.isClickable && renderStars(cell.content)}</div>
                    <div>
                      {cell.isInput && (
                        <input
                          type="text"
                          name="name"
                          id="name"
                          value={data.value[rowIndex][colIndex].content}
                          className="peer block w-full border-0  py-1.5 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6"
                          onChange={(e) =>
                            handleCellInputClick(
                              rowIndex,
                              colIndex,
                              e.target.value
                            )
                          }
                        />
                      )}
                    </div>
                  </div>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default FasciaChart;
