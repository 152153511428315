import React, { useEffect, useState } from "react";
import { getPaymentSummary } from "../../apis/visit";
const PaymentSummary: React.FC<{
  closeModal: () => void;
  visitId: any;
}> = ({ closeModal, visitId }) => {
  const [paymentSummary, setPaymentSummary] = useState<any>([]);

  useEffect(() => {
    fetchSummary();
  }, []);
  
  const fetchSummary = () => {
    getPaymentSummary(visitId).then((res: any) => {
      setPaymentSummary(res.data.data);
    });
  };

  return (
    <div className="gap-4 px-6 py-5 pt-3 bg-white ">
      <div className="py-8">
        <div className="grid grid-cols-2 py-1">
          <span>Total Fee </span>
          <span>₹ {parseFloat(paymentSummary.total).toFixed(2)}</span>
        </div>
        <div className="grid grid-cols-2 py-1">
          <span>Discount </span>
          <span className="text-indigo-700">₹{parseFloat(paymentSummary.discount).toFixed(2)}</span>
        </div>
        <div className="grid grid-cols-2 py-1">
          <span className="py-1">Amount To Be Paid</span>
          <span>₹{(parseFloat(paymentSummary.total) - parseFloat(paymentSummary.discount)).toFixed(2)}</span>
        </div>

        <div className="grid grid-cols-2 py-1">
          <span className="py-1">Paying</span>
          <span className="text-green-700">₹{parseFloat(paymentSummary.amountPaid).toFixed(2)}</span>
        </div>

        <div className="grid grid-cols-2 py-1 font-bold">
          <span className="py-1">Amount Due </span>
          <span className="text-red-700">₹{parseFloat(paymentSummary.amountDue).toFixed(2)}</span>
        </div>
      </div>
      <div className="flex items-center justify-start py-4 gap-x-6 border-gray-900/10 ">
        <button
          onClick={() => closeModal()}
          type="button"
          className="text-sm font-semibold leading-6 text-gray-900"
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default PaymentSummary;
