import React, { useEffect, useRef, useState } from "react";
import {
  createPatient,
  generateOtp,
  getDoctors,
  verifyOtp,
  verifyPatient,
} from "../apis/patient";
import { useNotification } from "../NotificationContext";
import { Select } from "antd";
import { User } from "../interfaces/User";
import { saveAppointment } from "../apis/visit";
import { checkDoctorAvailabilityForPatient } from "../apis/event";
import { useNavigate } from "react-router-dom";

const PatientAppointment = () => {
  const genders = [
    { id: "male", title: "Male" },
    { id: "female", title: "Female" },
    { id: "other", title: "Other" },
  ];

  const { showNotification } = useNotification();
  const [patient, setPatient] = useState<any>();
  const [patientId, setPatientId] = useState<any>();
  const [isPatientVerified, setPatientVerified] = useState<boolean>(false);
  const [isOtpVerified, setOtpVerified] = useState<boolean>(false);
  const [isVisibleOtpField, setOtpFieldVisible] = useState<boolean>(false);
  const [isResendOtp, setResendOtp] = useState<boolean>(false);
  const [isPatientCreate, setPatientCreate] = useState<boolean>(false);
  const [isRegister, setRegister] = useState<boolean>(false);
  const [phone, setPhone] = useState<any>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isVerifyButtonDisabled, setVerifyButtonDisabled] = useState(false);
  const [otp, setOtp] = useState<any>();
  const [doctors, setDoctors] = useState<Array<User>>([]);
  const datetimeRef = useRef<HTMLInputElement | null>(null);
  const Option = Select;
  const handleDoubleClick = () => {
    datetimeRef.current?.showPicker();
  };
  const [errors, setErrors] = useState<any>();
  const navigate = useNavigate();

  const [patientDetails, setPatientDetails] = useState({
    id: 0,
    firstName: "",
    middleName: "",
    lastName: "",
    phone: "",
    bloodGroup: "",
    gender: "male",
    age: "",
    birthDate: "",
    address: "",
    email: "",
    comments: "",
    registrationNumber: "",
    isTobacco: false,
    isSmoking: false,
    isAlcoholic: false,
    isDrugs: false,
    drugComments: "",
    referredBy: "",
  });

  const [visit, setVisit] = useState({
    id: 0,
    date: "",
    isInpatient: true,
    patientId: "",
    parentId: null,
    clinicUserId: "",
    clinicVisitTypeId: 1,
    assessment: {},
    meta: {
      height: "",
      weight: "",
    },
    fee: 0,
    discount: 0,
    medicines: [],
    services: [],
    symptoms: [],
    provisionalDiagnosis: [],
    finalDiagnosis: [],
  });

  useEffect(() => {
    fetchDoctors({});
  }, []);

  useEffect(() => {
    if (patientDetails) {
      setPatientId(patientDetails.id);
    }
  }, [patientDetails]);

  const verifyPatientPhone = () => {
    const { hasErrors, validationErrors } = validateForm();
    if (hasErrors) {
      setErrors(validationErrors);
      return;
    }
    setErrors(null);
    setLoading(true);
    verifyPatient(phone).then((res: any) => {
      if (res?.data?.phone === phone) {
        setPatientVerified(true);
        setRegister(true);
        setPatientCreate(false);

        setPatient(res.data);
        setPatientId(res.data.id);
        setLoading(false);
      } else {
        setPatientVerified(false);
        sendotp();
        setLoading(false);
      }
    });
  };

  const sendotp = () => {
    generateOtp({ phone: phone }).then((res) => {
      showNotification("success", "Otp sent successfully", "", "topRight");
      setOtpFieldVisible(true);
      setLoading(false);
      setVerifyButtonDisabled(true);

      setTimeout(() => {
        setResendOtp(true);
        setVerifyButtonDisabled(false);
      }, 120000);
    });
  };

  const checkOtp = () => {
    verifyOtp({ phone, otp })
      .then((res: any) => {
        setOtpVerified(true);
        setPatientCreate(true);
        setOtpFieldVisible(false);
      })
      .catch((err: any) => {
        showNotification("error", err.response.data.message, "", "topRight");
      });
  };

  const fetchDoctors = (params: any) => {
    getDoctors(params).then((res: any) => {
      const users = res.data;
      const filteredUsers = users.filter((user: any) => user.role === "doctor");
      setDoctors(filteredUsers);
    });
  };

  const handleAddPatient = () => {
    const { hasErrors, validationErrors } = validateForm();
    if (hasErrors) {
      setErrors(validationErrors);
      return;
    }
    setErrors(null);
    createPatient({
      ...patientDetails,
      phone,
    })
      .then((res: any) => {
        setPatientDetails(res.data.data);
        showNotification("success", "Registration succesfull", "", "topRight");
        setRegister(true);
      })
      .catch((err: any) => {
        showNotification("error", err.response.data.message, "", "topRight");
      });
  };

  const setFirstName = (firstName: string) => {
    setPatientDetails({
      ...patientDetails,
      firstName: firstName,
    });
  };

  const setlastName = (lastName: string) => {
    setPatientDetails({
      ...patientDetails,
      lastName: lastName,
    });
  };

  const setEmail = (email: string) => {
    setPatientDetails({
      ...patientDetails,
      email: email,
    });
  };

  const setGender = (gender: string) => {
    setPatientDetails({
      ...patientDetails,
      gender: gender,
    });
  };

  const setAddress = (address: string) => {
    setPatientDetails({
      ...patientDetails,
      address: address,
    });
  };

  const createScheduleVisit = async () => {
    const { hasErrors, validationErrors } = validateForm();
    if (hasErrors) {
      setErrors(validationErrors);
      return;
    }
    setErrors(null);

    const result = await checkDoctorAvailabilityForPatient({
      clinicUserId: visit.clinicUserId,
      date: new Date(visit.date).toISOString(),
    });
    if (result.data.availability) {
      await saveAppointment({
        ...visit,
        date: new Date(visit.date).toISOString(),
        patient_id: patientId,
      }).then((res: any) => {
        const visitId = res.id;
        showNotification(
          "success",
          "Appointment Added successfully",
          "",
          "topRight"
        );
        navigate(`/booked-appointment/${visitId}`);
      });
    } else {
      showNotification(
        "error",
        "Error",
        "Doctor is unavailable. Please select another date or time.",
        "topRight"
      );
    }

    return;
  };

  const handleUserChange = (clinicUserId: any) => {
    setVisit({
      ...visit,
      clinicUserId,
    });
  };

  const validateForm = () => {
    const validationErrors: any = {};
    if (visit && isRegister) {
      if (visit?.date === "") {
        validationErrors.date = "* date is required";
      }
      if (visit?.clinicUserId === "") {
        validationErrors.doctor = " Please select doctor";
      }
    }
    if (patientDetails && isPatientCreate) {
      if (patientDetails?.firstName === "") {
        validationErrors.firstName = "* First name is required";
      }
      if (patientDetails?.lastName === "") {
        validationErrors.lastName = "* Last name is required";
      }
      if (patientDetails?.gender === "") {
        validationErrors.gender = "Please select gender";
      }
    }

    if (phone === undefined || phone === null) {
      validationErrors.phone = "* Phone is required";
    } else if (phone && phone.length !== 10) {
      validationErrors.phone = "phone number should be 10 digits";
    }

    const hasErrors = Boolean(Object.keys(validationErrors).length);

    return { hasErrors, validationErrors };
  };

  return (
    <>
      <div className="px-4 my-10 sm:px-10">
        <div className="box-border mx-auto border-2 rounded-md max-w-7xl">
          <div className="flex p-6 m-0 border-b border-gray-200 rounded-md bg-primary">
            <h3 className="text-lg font-medium leading-6 text-white">
              Schedule An Appointment
            </h3>
          </div>
          <div className="px-2 pt-10 sm:px-6 lg:px-8">
            <div className="space-y-8 divide-y divide-gray-200">
              <div className="space-y-5 divide-gray-200">
                <div className="space-y-6 sm:space-y-5">
                  {isPatientVerified && (
                    <div className="space-y-6 sm:space-y-5">
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 sm:pt-5">
                        <div>
                          <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            First Name
                          </label>
                        </div>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <input
                            id="firstName"
                            name="firstName"
                            type="text"
                            value={patient.firstName}
                            readOnly
                            className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:border-gray-300 focus:ring focus:ring-gray-200 focus:ring-opacity-50 sm:text-sm"
                          />
                        </div>
                      </div>
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <div>
                          <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            Last Name
                          </label>
                        </div>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <input
                            id="lastName"
                            name="lastName"
                            type="text"
                            value={patient.lastName}
                            readOnly
                            className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:border-gray-300 focus:ring focus:ring-gray-200 focus:ring-opacity-50 sm:text-sm"
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  {isPatientCreate && (
                    <div className="space-y-6 sm:space-y-5">
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 sm:pt-5">
                        <div>
                          <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            First Name
                          </label>
                        </div>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <input
                            id="firstName"
                            name="firstName"
                            type="text"
                            value={patientDetails.firstName}
                            onChange={(e) => {
                              setFirstName(e.target.value);
                            }}
                            className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:border-gray-300 focus:ring focus:ring-gray-200 focus:ring-opacity-50 sm:text-sm"
                          />
                          {errors && errors.firstName && (
                            <span className="text-sm text-red-500 leading-0">
                              {errors.firstName}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <div>
                          <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            Last Name
                          </label>
                        </div>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <input
                            id="lastName"
                            name="lastName"
                            type="text"
                            value={patientDetails.lastName}
                            onChange={(e) => {
                              setlastName(e.target.value);
                            }}
                            className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:border-gray-300 focus:ring focus:ring-gray-200 focus:ring-opacity-50 sm:text-sm"
                          />
                          {errors && errors.lastName && (
                            <span className="text-sm text-red-500 leading-0">
                              {errors.lastName}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="mb-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                        Phone
                      </label>
                    </div>

                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <div className="flex">
                        <input
                          name="phone"
                          type="text"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                          className="block w-full max-w-lg mt-1 border-gray-300 rounded-md shadow-sm form-control focus:border-gray-300 focus:ring focus:ring-gray-200 focus:ring-opacity-50 sm:text-sm"
                          required
                          maxLength={10}
                        />

                        {!isOtpVerified && !isRegister ? (
                          <button
                            onClick={() => verifyPatientPhone()}
                            type="submit"
                            className={`px-3 py-2 ml-2 text-sm font-semibold rounded-md shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-dark ${
                              isLoading || isVerifyButtonDisabled
                                ? "text-gray-500 bg-gray-300 cursor-not-allowed"
                                : "text-white bg-primary hover:bg-hover"
                            }`}
                            disabled={isLoading || isVerifyButtonDisabled}
                          >
                            {isLoading ? (
                              <div className="inline-block w-4 h-4 border-2 border-t-2 border-white rounded-full border-t-transparent animate-spin"></div>
                            ) : (
                              <span>
                                {isResendOtp ? "Resend Otp" : "Verify"}
                              </span>
                            )}
                          </button>
                        ) : (
                          <div className="m-3">
                            <span className="inline-flex items-center px-2 py-1 text-xs font-medium text-green-700 rounded-md bg-green-50 ring-1 ring-inset ring-green-600/20">
                              Verified
                            </span>
                          </div>
                        )}
                      </div>
                      {errors && errors.phone && (
                        <span className="text-sm text-red-500 leading-0">
                          {errors.phone}
                        </span>
                      )}
                    </div>
                  </div>
                  {isPatientVerified && (
                    <div className="space-y-6 sm:space-y-5">
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <div>
                          <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            Email
                          </label>
                        </div>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <input
                            id="email"
                            name="email"
                            type="text"
                            value={patient.email}
                            readOnly
                            className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:border-gray-300 focus:ring focus:ring-gray-200 focus:ring-opacity-50 sm:text-sm"
                          />
                        </div>
                      </div>
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <div>
                          <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            Address
                          </label>
                        </div>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <input
                            id="address"
                            name="address"
                            type="text"
                            value={patient.address}
                            required
                            className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:border-gray-300 focus:ring focus:ring-gray-200 focus:ring-opacity-50 sm:text-sm"
                          />
                        </div>
                      </div>
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <span className="text-sm font-medium text-gray-700">
                          Gender
                        </span>
                        <div className="space-x-4 space-y-4 sm:flex sm:items-center xl:space-x-10 sm:space-y-0">
                          {genders.map((gender) => (
                            <div key={gender.id} className="flex items-center">
                              <input
                                id={gender.id}
                                name="gender"
                                value={gender.id}
                                type="radio"
                                checked={patient.gender === gender.id}
                                className="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-600"
                              />
                              <label
                                htmlFor={gender.id}
                                className="block ml-3 text-sm font-medium leading-6 text-gray-900"
                              >
                                {gender.title}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                  {isVisibleOtpField && (
                    <div className="pb-2 mb-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                      <div>
                        <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                          Enter Otp
                        </label>
                      </div>

                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <div className="flex">
                          <input
                            id="otp"
                            name="otp"
                            type="text"
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                            required
                            className="block w-full max-w-lg mt-1 border-gray-300 rounded-md shadow-sm form-control focus:border-gray-300 focus:ring focus:ring-gray-200 focus:ring-opacity-50 sm:text-sm"
                          />
                          {errors && errors.otp && (
                            <p className="mt-2 text-sm text-red-600">
                              {errors.otp}
                            </p>
                          )}

                          <button
                            onClick={() => checkOtp()}
                            type="submit"
                            className="px-3 py-2 ml-2 text-sm font-semibold text-white rounded-md shadow-sm bg-primary hover:bg-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-dark"
                          >
                            Verify otp
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                  {isPatientCreate && (
                    <div className="space-y-6 sm:space-y-5">
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <div>
                          <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            Email
                          </label>
                        </div>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <input
                            id="email"
                            name="email"
                            type="text"
                            value={patientDetails.email}
                            onChange={(e) => {
                              setEmail(e.target.value);
                            }}
                            className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:border-gray-300 focus:ring focus:ring-gray-200 focus:ring-opacity-50 sm:text-sm"
                          />
                        </div>
                      </div>
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <div>
                          <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            Address
                          </label>
                        </div>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <input
                            id="address"
                            name="address"
                            type="text"
                            value={patientDetails.address}
                            onChange={(e) => {
                              setAddress(e.target.value);
                            }}
                            className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:border-gray-300 focus:ring focus:ring-gray-200 focus:ring-opacity-50 sm:text-sm"
                          />
                        </div>
                      </div>
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <span className="text-sm font-medium text-gray-700">
                          Gender
                        </span>
                        <div className="space-x-4 space-y-4 sm:flex sm:items-center xl:space-x-10 sm:space-y-0">
                          {genders.map((gender) => (
                            <div key={gender.id} className="flex items-center">
                              <input
                                id={gender.id}
                                name="gender"
                                value={gender.id}
                                type="radio"
                                checked={patientDetails.gender === gender.id}
                                onChange={(e) => {
                                  setGender(e.target.value);
                                }}
                                className="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-600"
                              />
                              <label className="block ml-3 text-sm font-medium leading-6 text-gray-900">
                                {gender.title}
                              </label>
                            </div>
                          ))}
                        </div>
                        {errors && errors.gender && (
                          <span className="text-sm text-red-500 leading-0">
                            {errors.gender}
                          </span>
                        )}
                      </div>
                      {!isRegister && (
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                          <div className="pb-3 mt-1 sm:mt-0 sm:col-span-2">
                            <button
                              onClick={handleAddPatient}
                              type="submit"
                              className="px-3 py-2 ml-2 text-sm font-semibold text-white rounded-md shadow-sm bg-primary hover:bg-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-dark"
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>

              {isRegister && (
                <>
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                      Date
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <input
                        id="datetime"
                        name="date"
                        type="datetime-local"
                        value={visit?.date}
                        onChange={(e) =>
                          setVisit({ ...visit, date: e.target.value })
                        }
                        onDoubleClick={handleDoubleClick}
                        ref={datetimeRef}
                        className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:border-gray-300 focus:ring focus:ring-gray-200 focus:ring-opacity-50 sm:text-sm"
                      />
                      {errors && errors.date && (
                        <span className="text-sm text-red-500 leading-0">
                          {errors.date}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                      Doctor Name
                    </label>
                    <div className="mt-1">
                      <Select
                        style={{ width: "130%", height: "38px" }}
                        onChange={handleUserChange}
                        value={visit.clinicUserId}
                      >
                        {doctors.map((doctor) => (
                          <Option
                            key={doctor.id}
                            value={doctor.id}
                            style={{ height: "38px" }}
                          >
                            {doctor.firstName + " " + doctor.lastName}
                          </Option>
                        ))}
                      </Select>
                      {errors && errors.doctor && (
                        <span className="text-sm text-red-500 leading-0">
                          {errors.doctor}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="py-5">
                    <div className="flex justify-start">
                      <button
                        type="button"
                        onClick={() => createScheduleVisit()}
                        className="px-4 py-2 ml-3 text-sm font-medium text-gray-100 border border-gray-300 rounded-md shadow-sm bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                      >
                        Schedule An Appointment
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PatientAppointment;
