import { Layout, Responsive, WidthProvider } from "react-grid-layout";
import { LayoutComponent } from "../../interfaces/LayoutComponent";
import { classNames } from "../../utils/CssUtil";
import { ChangeEvent } from "react";
const ResponsiveGridLayout = WidthProvider(Responsive);

type Page = {
  id: string;
  label: string;
  gridItems: LayoutComponent[];
  layout: Layout[];
  type: string;
  component: string;
};

type PageSettingsProps = {
  isPageModalOpen: boolean;
  layoutPages: Array<Page>;
  editablePageIndex: number | null;
  onAddPage: () => void;
  onDeletePage: (index: number) => void;
  onLabelChange: (e: ChangeEvent<HTMLInputElement>, index: number) => void;
  onPageEditable: (index: number) => void;
  onPositionChange: (pages: Array<Layout>) => void;
};
const PageSettings: React.FC<PageSettingsProps> = ({
  isPageModalOpen,
  editablePageIndex,
  layoutPages,
  onAddPage,
  onDeletePage,
  onLabelChange,
  onPageEditable,
  onPositionChange,
}) => {
  return (
    <div
      className={`absolute z-10 top-0 right-0 h-full bg-[#ECEFFF] border border-gray-300 rounded-md text-white  ease-in-out duration-300 transition-all transform ${
        isPageModalOpen ? "translate-x-0" : "translate-x-[calc(100%+1rem)] "
      } w-64`}
    >
      <div className="h-full p-4 text-center ">
        <div className="flex justify-between">
          <h2 className="text-lg font-semibold text-gray-600 ">
            Page Settings
          </h2>
          <button
            onClick={() => onAddPage()}
            className="text-lg font-semibold text-gray-600 "
          >
            +
          </button>
        </div>
        <ResponsiveGridLayout
          className="relative flex justify-start mt-2 overflow-y-scroll text-center border rounded-md layout "
          isResizable={false}
          isDraggable={true}
          autoSize={true}
          draggableHandle=".drag-handle"
          cols={{ lg: 1, md: 1, sm: 1, xs: 1, xxs: 1 }}
          layouts={{
            lg: layoutPages.map((page) => {
              return {
                i: page.id, // Combine ID and label
                x: 0,
                y: 0,
                w: 1,
                h: 1,
                isResizable: false,
              };
            }),
          }}
          onLayoutChange={(layout: Layout[]) => {
            onPositionChange(layout);
          }}
          rowHeight={160}
        >
          {layoutPages.map((page, index) => {
            return (
              <div
                key={page.id}
                className={classNames(
                  page.type === "default"
                    ? "striked-background-diagonal"
                    : "bg-white",
                  "relative flex items-center justify-center w-8 h-32 text-center  border border-gray-300 rounded-md shadow-md "
                )}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="absolute top-0 left-0 z-50 w-5 h-5 text-black cursor-pointer drag-handle"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                  />
                </svg>
                {editablePageIndex !== null && editablePageIndex === index ? (
                  <input
                    className="z-50 w-full cursor-text h-8 block  rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    type="text"
                    value={page.label}
                    onChange={(e) => onLabelChange(e, index)}
                  />
                ) : (
                  <span className="text-black underline cursor-pointer">
                    {page.label}
                  </span>
                )}
                {page.type !== "default" && (
                  <div className="absolute inset-0 w-full h-full transition-opacity duration-300 opacity-0 hover:opacity-100">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="absolute top-0 right-0 w-5 h-5 text-gray-500 cursor-pointer"
                      onClick={() => onPageEditable(index)}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                      />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="absolute top-0 w-5 h-5 text-gray-500 cursor-pointer right-6"
                      onClick={() => onDeletePage(index)}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                      />
                    </svg>
                  </div>
                )}
              </div>
            );
          })}
        </ResponsiveGridLayout>
      </div>
    </div>
  );
};

export default PageSettings;
