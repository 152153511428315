import React, { useContext, useEffect, useState } from 'react';
import { useNotification } from '../../NotificationContext';
import { getMedicine, saveMedicine, updateMedicine } from '../../apis/medicine';
import { Select } from 'antd';
import { Company } from '../../interfaces/Company';
import { getCompanies } from '../../apis/company';
import { LanguageContext } from '../../providers/LanguageProvider';
const AddMedicine: React.FC<{
  closeModal: () => void;
  editableId: any;
}> = ({ closeModal, editableId }) => {
  const defaultMedicine = {
    name: '',
    molecule: '',
    companyId: null,
    form: '',
    power: '',
  };
  const [medicine, setMedicine] = useState(defaultMedicine);
  const [errors, setErrors] = useState<any>();
  const { showNotification } = useNotification();
  const [companies, setCompanies] = useState<Array<Company>>([]);
  const Option = Select;
  const { translate: t } = useContext(LanguageContext);

  const forms = [
    {
      name: 'Tablet',
      value: 'tablet',
    },

    {
      name: 'Capsule',
      value: 'capsule',
    },

    {
      name: 'Syrup',
      value: 'syrup',
    },

    {
      name: 'Drops',
      value: 'drops',
    },

    {
      name: 'Creame',
      value: 'creame',
    },

    {
      name: 'Lotion',
      value: 'lotion',
    },

    {
      name: 'gel',
      value: 'gel',
    },

    {
      name: 'Powder',
      value: 'powder',
    },

    {
      name: 'Soap',
      value: 'soap',
    },
  ];

  useEffect(() => {
    fetchCompanies({});
    if (editableId) {
      fetchMedicine(editableId);
    }
  }, [editableId]);

  const fetchMedicine = (id: number) => {
    getMedicine(id).then((res: any) => {
      setMedicine(res.data);
    });
  };

  const fetchCompanies = (params: {}) => {
    getCompanies(params).then((res: any) => {
      setCompanies(res.data);
    });
  };

  const validateForm = () => {
    const validationErrors: any = {};
    if (medicine?.name === '') {
      validationErrors.name = '* Name is required';
    }
    if (medicine?.molecule === '') {
      validationErrors.molecule = '* Molecule is required';
    }

    if (medicine?.companyId === null) {
      validationErrors.company = '* Company is required';
    }
    if (medicine?.form === '') {
      validationErrors.form = '* Form is required';
    }

    if (medicine?.power === '') {
      validationErrors.power = '* Power is required';
    }

    const hasErrors = Boolean(Object.keys(validationErrors).length);

    return { hasErrors, validationErrors };
  };

  const addMedicine = () => {
    const { hasErrors, validationErrors } = validateForm();
    if (hasErrors) {
      setErrors(validationErrors);
      return;
    }
    setErrors(null);
    saveMedicine({
      ...medicine,
    }).then((res: any) => {
      showNotification(
        'success',
        'Medicine Added successfully',
        '',
        'topRight'
      );
      closeModal();
    });
  };

  const updateMedicineData = () => {
    updateMedicine({
      ...medicine,
      id: editableId,
    }).then((res) => {
      showNotification(
        'success',
        'Medicine Updated successfully',
        '',
        'topRight'
      );
      closeModal();
    });
  };

  const onSave = () => {
    if (editableId) {
      updateMedicineData();
    } else {
      addMedicine();
    }
  };

  const setForm = (form: string) => {
    setMedicine({
      ...medicine,
      form,
    });
  };

  const handleCompanyChange = (companyId: any) => {
    setMedicine({
      ...medicine,
      companyId,
    });
  };

  return (
    <div className=''>
      <span className='font-medium'>
        {editableId ? t('update') : t('add')} {t('medicines')}
      </span>
      <div className='pt-2'>
        <label className='block text-sm font-medium leading-6 text-gray-900'>
          {t('name')}
        </label>
        <div className='mt-1'>
          <input
            id='name'
            name='name'
            type='text'
            value={medicine?.name}
            onChange={(e) => setMedicine({ ...medicine, name: e.target.value })}
            className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
          />
        </div>

        {errors && errors.name && (
          <span className='text-sm text-red-500 leading-0'>{errors.name}</span>
        )}
      </div>
      <div className='pt-2'>
        <label className='block text-sm font-medium leading-6 text-gray-900'>
          {t('molecule')}
        </label>
        <div className='mt-1'>
          <input
            id='molecule'
            name='molecule'
            type='text'
            value={medicine?.molecule}
            onChange={(e) =>
              setMedicine({ ...medicine, molecule: e.target.value })
            }
            className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
          />
        </div>

        {errors && errors.molecule && (
          <span className='text-sm text-red-500 leading-0'>
            {errors.molecule}
          </span>
        )}
      </div>

      <div className='pt-2'>
        <label className='block text-sm font-medium leading-6 text-gray-900'>
          {t('company')}
        </label>
        <div className='mt-1'>
          <Select
            style={{ width: '100%' }}
            onChange={handleCompanyChange}
            value={medicine.companyId}
          >
            {companies.map((company) => (
              <Option key={company.id} value={company.id}>
                {company.name}
              </Option>
            ))}
          </Select>
        </div>

        {errors && errors.company && (
          <span className='text-sm text-red-500 leading-0'>
            {errors.company}
          </span>
        )}
      </div>
      <div className='pt-2'>
        <label className='block text-sm font-medium leading-6 text-gray-900'>
          {t('form')}
        </label>
        <div className='mt-1'>
          <Select
            style={{ width: '100%' }}
            onChange={setForm}
            value={medicine.form}
            options={forms}
          />
        </div>

        {errors && errors.form && (
          <span className='text-sm text-red-500 leading-0'>{errors.form}</span>
        )}
      </div>

      <div className='pt-2'>
        <label className='block text-sm font-medium leading-6 text-gray-900'>
          {t('power')}
        </label>
        <div className='mt-1'>
          <input
            id='power'
            name='power'
            type='text'
            value={medicine?.power}
            onChange={(e) =>
              setMedicine({ ...medicine, power: e.target.value })
            }
            className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
          />
        </div>

        {errors && errors.power && (
          <span className='text-sm text-red-500 leading-0'>{errors.power}</span>
        )}
      </div>

      <div className='flex items-center justify-start py-4 gap-x-6 border-gray-900/10 '>
        <button
          onClick={() => onSave()}
          type='submit'
          className='px-3 py-2 text-sm font-semibold text-white rounded-md shadow-sm bg-primary hover:bg-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-dark'
        >
          {editableId ? t('update') : t('save')}
        </button>
        <button
          onClick={() => closeModal()}
          type='button'
          className='text-sm font-semibold leading-6 text-gray-900'
        >
          {t('cancel')}
        </button>
      </div>
    </div>
  );
};

export default AddMedicine;
