import React, { useContext, useEffect, useState } from 'react';
import { Pagination, PaginationProps } from 'antd';
import { Log } from '../../interfaces/Log';
import { getLogs } from '../../apis/log';
import { LanguageContext } from '../../providers/LanguageProvider';
const Logs = () => {
  const [meta, setMeta] = useState<any>();
  const [logs, setLogs] = useState<Array<Log>>([]);
  const { translate: t } = useContext(LanguageContext);

  useEffect(() => {
    fetchLogs({ per_page: 10 });
  }, []);

  const fetchLogs = (params = {}) => {
    getLogs(params).then((res: any) => {
      setLogs(res.data);
      setMeta(res.meta);
    });
  };

  //use per_page
  const onPaginationChange: PaginationProps['onChange'] = (page, pageSize) => {
    fetchLogs({ page, per_page: pageSize });
  };

  return (
    <div className='px-4 sm:px-6 lg:px-8'>
      <div className='sm:flex sm:items-center'>
        <div className='sm:flex-auto'>
          <h1 className='text-base font-semibold leading-6 text-gray-900'>
            {t('logs')}
          </h1>
          <p className='mt-2 text-sm text-gray-700'>{t('log_description')}</p>
        </div>
        <div className='mx-2 mt-4 sm:mt-0 sm:ml-16 sm:flex'></div>
      </div>
      <div className='flow-root mt-8'>
        <div className='-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div className='inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8'>
            <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg'>
              <table className='min-w-full divide-y divide-gray-300'>
                <thead className='bg-gray-50'>
                  <tr>
                    <th
                      scope='col'
                      className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                    >
                      {t('date')}
                    </th>
                    <th
                      scope='col'
                      className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                    >
                      {t('title')}
                    </th>
                    <th
                      scope='col'
                      className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                    >
                      {t('path')}
                    </th>
                    <th
                      scope='col'
                      className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                    >
                      {t('log level')}
                    </th>
                  </tr>
                </thead>
                <tbody className='bg-white divide-y divide-gray-200'>
                  {logs.map((log) => (
                    <tr key={log.id}>
                      <td className='py-4 pl-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap sm:pl-6'>
                        {log.createdAt}
                      </td>
                      <td className='px-3 py-4 text-sm text-gray-500 whitespace-nowrap'>
                        {log.title}
                      </td>

                      <td className='px-3 py-4 text-sm text-gray-500 whitespace-nowrap'>
                        {log.path}
                      </td>
                      <td className='py-4 pl-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap sm:pl-0'>
                        {log.logLevel === 'error' ? (
                          <span className='inline-flex items-center rounded-full bg-red-100 px-2.5 py-0.5 text-xs font-medium text-red-800'>
                            {t(log.logLevel)}
                          </span>
                        ) : (
                          <span className='inline-flex items-center rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800'>
                            {t(log.logLevel)}
                          </span>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className='flex justify-end pt-5'>
              <Pagination
                defaultCurrent={1}
                defaultPageSize={10}
                total={meta?.total}
                onChange={onPaginationChange}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Logs;
