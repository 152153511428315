import { LayoutComponent, Page } from "../interfaces/LayoutComponent";

export const updateGridItem = (
  gridItem: LayoutComponent,
  comp: LayoutComponent
) => ({
  ...gridItem,
  minH: comp.minH,
  maxH: comp.maxH,
  minW: comp.minW,
  maxW: comp.maxW,
  h: comp.h,
  w: comp.w,
  data: {
    label: comp.data.label,
    isRequired: comp.data.isRequired,
    key: comp.data.key,
    value: comp.data.value,
  },
});

export const updateLayoutItem = (layoutItem: any, comp: LayoutComponent) => ({
  ...layoutItem,
  minH: comp.minH,
  maxH: comp.maxH,
  minW: comp.minW,
  maxW: comp.maxW,
  h: comp.h,
  w: comp.w,
});

export const createNewLayoutComponent = (
  id: number,
  comp: LayoutComponent
) => ({
  i: id.toString(),
  x: comp.x,
  y: comp.y,
  h: comp.h,
  w: comp.w,
  minH: comp.minH,
  maxH: comp.maxH,
  minW: comp.minW,
  maxW: comp.maxW,
});

export const isComponentExist = (page: Page, componentId: number) => {
  return page.layout?.some(
    (layoutItem) => parseInt(layoutItem.i) === componentId
  );
};

export const removeComponent = (
  pages: Page[],
  pageIndex: number,
  componentId: number
): Page[] => {
  // Remove component from gridItems
  const updatedGridItems = pages[pageIndex].gridItems.filter(
    (item) => item.id !== componentId
  );

  // Remove component from layout
  const updatedLayout = pages[pageIndex].layout.filter(
    (item) => parseInt(item.i) !== componentId
  );

  // Update the page with new gridItems and layout
  const updatedPages = [...pages];
  updatedPages[pageIndex] = {
    ...updatedPages[pageIndex],
    gridItems: updatedGridItems,
    layout: updatedLayout,
  };

  return updatedPages;
};

export const getNewValue = (component: string, e: any): any => {
  switch (component) {
    case "AmsaStaticSelect":
    case "AmsaEditor":
    case "PhysiotherapistChart":
      return e;
    case "AmsaInput":
    case "AmsaDateInput":
      return e.target.value;
    default:
      return null;
  }
};

// Helper function to generate layout object
export const generateLayoutObject = (
  gridItems: LayoutComponent[]
): {
  layoutContent: Record<string, string>;
  vitals: Record<string, any>;
} => {
  const layoutContent: Record<string, string> = {};
  const vitals: Array<Record<string, string>> = [];

  gridItems.forEach((item) => {
    if (item.data.isVital) {
      vitals.push({
        label: item.data.key,
        value: item.data.value,
        unit: item.data.selectedUnit,
      });
    } else {
      layoutContent[item.data.key] = item.data.value;
    }
  });

  return { layoutContent, vitals };
};

// Helper function to update the grid items
export const updateGridItems = (
  gridItems: LayoutComponent[],
  gridItemToUpdate: LayoutComponent,
  e: any
): LayoutComponent[] => {
  return gridItems.map((item) => {
    if (item.id !== gridItemToUpdate.id) return item;

    const newValue = getNewValue(gridItemToUpdate.component, e);

    // Only update the item if the value has changed
    if (item.data.value !== newValue) {
      return {
        ...item,
        data: { ...item.data, value: newValue },
      };
    }

    return item;
  });
};
