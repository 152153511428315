import React, { memo } from "react";
import AmsaInput from "./AmsaInput";
import AmsaStaticSelect from "./AmsaStaticSelect";
import AmsaDateInput from "./AmsaDateInput";
import AmsaEditor from "./AmsaEditor";
import FasciaChart from "../../components/FasciaChart";

const componentRegistry: { [key: string]: React.FC<any> } = {
  AmsaInput,
  AmsaStaticSelect,
  AmsaDateInput,
  AmsaEditor,
  FasciaChart,
};

interface DynamicComponentRendererProps {
  gridItem: any;
  setValue: any;
  shouldFocus?: boolean;
}

const DynamicComponent: React.FC<DynamicComponentRendererProps> = ({
  gridItem,
  setValue,
  shouldFocus,
}) => {
  // Check if the component with the given name exists in the registry
  const Component = componentRegistry[gridItem.component];

  if (!Component) {
    return <div>Component {gridItem.component} not found.</div>;
  }

  return (
    <Component
      data={gridItem.data}
      setValue={setValue}
      shouldFocus={shouldFocus}
    />
  );
};

export default memo(DynamicComponent);
