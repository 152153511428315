import ReactGridLayout, { Layout } from "react-grid-layout";
import { LayoutComponent } from "../../interfaces/LayoutComponent";
import { classNames } from "../../utils/CssUtil";
import DynamicComponent from "../layoutDesign/DynamicComponent";

type CustomPageProps = {
  gridItems: LayoutComponent[];
  layout: Layout[];
  layoutKey: string;
  onSetValue: (e: any, LayoutComponent: LayoutComponent, index: number) => void;
};
const CustomPage: React.FC<CustomPageProps> = ({
  gridItems,
  layout,
  onSetValue,
  layoutKey,
}) => {
  return (
    <div className="h-[calc(100vh-273px)]" key={layoutKey}>
      <ReactGridLayout
        autoSize={false}
        resizeHandle={false}
        className="layout"
        layout={layout}
        rowHeight={40}
        width={1200}
      >
        {gridItems.map((gridItem: any, index) => {
          return (
            <div
              className={classNames(
                gridItem.component === "FasciaChart"
                  ? "border-0 bg-transperant"
                  : "bg-white border-2 border-solid",
                "p-2 "
              )}
              key={gridItem.id}
            >
              <DynamicComponent
                key={gridItem.id}
                gridItem={gridItem}
                setValue={(e: any) => onSetValue(e, gridItem, index)}
              />
            </div>
          );
        })}
      </ReactGridLayout>
    </div>
  );
};

export default CustomPage;
