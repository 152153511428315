import { LayoutComponent } from "../interfaces/LayoutComponent";
import { physiotherapyData } from "./Physiotherapy";

export const layoutComponents: LayoutComponent[] = [
  {
    id: 1,
    component: "AmsaInput",
    class: "",
    label: "Text Input",
    w: 3,
    h: 2,
    x: 0,
    y: 0,
    minH: 2,
    maxH: 2,
    minW: 1,
    maxW: 4,
    data: {
      key: "textInput",
      label: "label",
      isRequired: false,
      value: "",
    },
  },
  {
    id: 2,
    component: "AmsaDateInput",
    class: "",
    label: "Date Input",
    w: 3,
    h: 2,
    x: 0,
    y: 0,
    minH: 2,
    maxH: 2,
    minW: 1,
    maxW: 3,
    data: {
      key: "dateInput",
      label: "label",
      isRequired: false,
      value: "",
    },
  },
  {
    id: 3,
    component: "AmsaStaticSelect",
    class: "Class 1",
    label: "Static Select",
    w: 3,
    h: 2,
    x: 0,
    y: 0,
    minH: 2,
    maxH: 2,
    minW: 1,
    maxW: 3,
    data: {
      key: "selectInput",
      label: "label",
      isRequired: false,
      value: "",
      options: [
        {
          label: "Option A",
          value: "Option A",
        },
        {
          label: "Option B",
          value: "Option B",
        },
      ],
    },
  },
  {
    id: 4,
    component: "AmsaEditor",
    class: "",
    label: "Rich Text Editor",
    w: 4,
    h: 6,
    x: 0,
    y: 0,
    minH: 6,
    maxH: 12,
    minW: 3,
    maxW: 5,
    data: {
      key: "textEditor",
      label: "label",
      isRequired: false,
      value: "",
    },
  },
  {
    id: 5,
    component: "FasciaChart",
    class: "",
    label: "Fascia Chart",
    w: 10,
    h: 6,
    x: 0,
    y: 0,
    minH: 6,
    maxH: 12,
    minW: 12,
    maxW: 12,
    data: {
      key: "fasciaChart",
      label: "label",
      isRequired: false,
      value: physiotherapyData,
    },
  },
];
