import React, { useEffect, useState } from "react";
import { getVisitTypes } from "../../apis/visit";
import { useNavigate, useParams } from "react-router-dom";
export interface VisitType {
  id: number;
  name: string;
  code:string;
  icon: string;
  isVisible:boolean;
}
const VisitTypes = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [visitTypes, setVisitTypes] = useState<Array<VisitType>>([]);
  useEffect(() => {
    fetchVisitTypes();
  }, []);

  const fetchVisitTypes = () => {
    getVisitTypes().then((res: any) => {
      setVisitTypes(res.data);
    });
  };

  const goToNewVisit = (typeId: number) => {
    navigate(`/visits/types/${typeId}/patients/${params.patientId}`);
  };

  const images = [
    {
      id: 1,
      img: "/Online Doctor-amico.png",
    },
    {
      id: 2,
      img: "/Online Doctor-cuate.png",
    },
    {
      id: 3,
      img: "/Online Doctor-pana.png",
    },
    {
      id: 4,
      img: "/Online Doctor-rafiki.png",
    },
    {
      id: 5,
      img: "/Online Doctor-pana.png",
    },
    {
      id: 6,
      img: "/Online Doctor-amico.png",
    },
  ];

  return (
    <div>
      <div className="grid grid-cols-2 gap-4 p-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 hover:cursor-pointer">
        {visitTypes &&
          visitTypes.length > 0 &&
          visitTypes.map((i) => {
            const image = images.find((img) => img.id === i.id);
            return (
              <div
                key={i.id}
                onClick={() => goToNewVisit(i.id)}
                className="rounded-lg shadow bg-primary text-light hover:opacity-90 hover:visible"
              >
                <div className="flex flex-col px-3 py-6 text-center place-items-center">
                  <span className="mt-10 font-sans font-bold capitalize">
                    {i.name}
                  </span>
                  {image && (
                    <img
                      className="w-full h-full mt-4"
                      src={image.img}
                      alt={i.name}
                    />
                  )}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default VisitTypes;
