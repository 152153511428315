import React, { useEffect, useState } from "react";
import { Patient } from "../../interfaces/Patient";
import { useParams } from "react-router-dom";
import { getPatient } from "../../apis/patient";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  changePatient,
  selectPatient,
} from "../../features/patient/patientSlice";
import { EditorState, convertFromRaw } from "draft-js";
// import { stateToHTML } from "draft-js-export-html";

const Summary: React.FC<{ errors: Patient | null }> = ({ errors }) => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const patient = useAppSelector(selectPatient);

  const [medicines, setMedicines] = useState<any>();
  const [familyHistory, setFamilyHistory] = useState<any>();

  useEffect(() => {
    if (params && params.patientId) {
      getPatient(params.patientId).then((res: any) => {
        const response = res.data.data;
        if (response?.historyData?.ongoingMedicines) {
          convertTextToPlainText(
            response?.historyData?.ongoingMedicines,
            setMedicines
          );
        }
        if (response?.historyData?.familyHistory) {
          convertTextToPlainText(
            response?.historyData?.familyHistory,
            setFamilyHistory
          );
        }
        dispatch(changePatient(res.data.data));
      });
    }
  }, [dispatch, params]);

  const convertTextToPlainText = (
    text: any,
    setState: React.Dispatch<React.SetStateAction<string>>
  ) => {
    if (text) {
      const contentState = convertFromRaw(JSON.parse(text));
      const plainText = contentState.getPlainText();
      setState(plainText);
    }
  };

  return (
    <div className="p-4 bg-white">
      <div>
        <h3 className="text-base font-semibold leading-7 text-gray-900">
          Summary
        </h3>
        <p className="max-w-2xl mt-1 text-sm leading-6 text-gray-700">
          Patient's personal details and medical history
        </p>
      </div>
      <div className="mt-6">
        <dl className="grid grid-cols-1 sm:grid-cols-4">
          <div className="px-4 py-6 border-t border-gray-200 sm:col-span-1 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">
              Full name
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-500 sm:mt-2">
              {patient.firstName + " " + patient.lastName}
            </dd>
          </div>
          <div className="px-4 py-6 border-t border-gray-200 sm:col-span-1 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">
              Phone
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-500 sm:mt-2">
              {patient.phone}
            </dd>
          </div>
          <div className="px-4 py-6 border-t border-gray-200 sm:col-span-1 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">
              Email address
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-500 sm:mt-2">
              {patient.email}
            </dd>
          </div>
          <div className="px-4 py-6 border-t border-gray-200 sm:col-span-1 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">
              Gender
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-500 sm:mt-2">
              {patient.gender}
            </dd>
          </div>
          <div className="px-4 py-6 border-t border-gray-200 sm:col-span-2 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">
              Ongoing Medicines
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-500 sm:mt-2">
              {/* {patient.historyData.ongoingMedicines} */}
              {medicines}
            </dd>
          </div>
          <div className="px-4 py-6 border-t border-gray-200 sm:col-span-2 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">
              Family History
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-500 sm:mt-2">
              {/* {patient.historyData.familyHistory} */}
              {familyHistory}
            </dd>
          </div>
          <div className="px-4 py-6 border-t border-gray-200 sm:col-span-2 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">
              Habits
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-500 sm:mt-2">
              <span>{patient.isAlcoholic && "Alcoholic "}</span>
              <span>{patient.isSmoking && "Smoking "}</span>
              <span>{patient.isTobacco && "Tobacco "}</span>
              <span>{patient.isDrugs && "Drug addicted "}</span>
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
};

export default Summary;
