import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getDashboardCounts } from "../../apis/dashboard";
import { LanguageContext } from "../../providers/LanguageProvider";
import BarChart from "../../components/PatientChart";
import { ChartData } from "chart.js";

const Dashboard = () => {
  const navigate = useNavigate();
  const [totalPatients, setPatientCount] = useState<any>();
  const [thisMonthsVisitCount, setThisMonthsVisitCounts] = useState<any>();
  const [medicinesCount, setMedicinesCount] = useState<any>();
  const [thisMonthsIncomeCount, setThisMonthsIncomeCount] = useState<any>();
  const [chartData, setChartData] =
    useState<ChartData<"bar", (number | [number, number] | null)[], unknown>>();
  const { translate: t } = useContext(LanguageContext);
  const { updateLanguage } = useContext(LanguageContext);

  useEffect(() => {
    const user = JSON.parse(window.localStorage.getItem("user") || "{}");
    updateLanguage(user.preferredLanguage);
    fetchDashboardCounts();
  }, [updateLanguage]);

  const fetchDashboardCounts = () => {
    getDashboardCounts().then((res: any) => {
      setPatientCount(res.data.totalPatients);
      setThisMonthsVisitCounts(res.data.monthVisitCount);
      setMedicinesCount(res.data.totalMedicines);
      setThisMonthsIncomeCount(res.data.monthVisitFee);
      setChartData(res.data.monthlyCount);
    });
  };

  return (
    <>
      <div>
        <div className="text-sm font-medium text-gray-500"></div>
        <ul className="grid grid-cols-1 gap-5 mt-3 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4">
          <li
            className="flex col-span-1 rounded-md shadow-sm cursor-pointer"
            onClick={() => {
              navigate("/patients");
            }}
          >
            <div className="flex items-center justify-center flex-shrink-0 w-16 text-sm font-medium text-white bg-pink-600 rounded-l-md">
              TP
            </div>
            <div className="flex items-center justify-between flex-1 truncate bg-white border-t border-b border-r border-gray-200 rounded-r-md">
              <div className="flex-1 px-4 py-2 text-sm truncate">
                <span className="font-medium text-gray-900 hover:text-gray-600">
                  {t("total_patients")}
                </span>
                <p className="text-gray-500">{totalPatients}</p>
              </div>
              <div className="flex-shrink-0 pr-2">
                <button
                  className="inline-flex items-center justify-center w-8 h-8 text-gray-400 bg-transparent bg-white rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() => {
                    navigate("/patients");
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M13 7l5 5m0 0l-5 5m5-5H6"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </li>

          <li
            className="flex col-span-1 rounded-md shadow-sm cursor-pointer"
            onClick={() => {
              navigate("/visit-register");
            }}
          >
            <div className="flex items-center justify-center flex-shrink-0 w-16 text-sm font-medium text-white bg-purple-600 rounded-l-md">
              MV
            </div>
            <div className="flex items-center justify-between flex-1 truncate bg-white border-t border-b border-r border-gray-200 rounded-r-md">
              <div className="flex-1 px-4 py-2 text-sm truncate">
                <span className="font-medium text-gray-900 hover:text-gray-600">
                  {t("this_months_visits")}
                </span>
                <p className="text-gray-500">{thisMonthsVisitCount}</p>
              </div>
              <div className="flex-shrink-0 pr-2">
                <button
                  className="inline-flex items-center justify-center w-8 h-8 text-gray-400 bg-transparent bg-white rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() => {
                    navigate("/visit-register");
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M13 7l5 5m0 0l-5 5m5-5H6"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </li>

          <li
            className="flex col-span-1 rounded-md shadow-sm cursor-pointer"
            onClick={() => {
              navigate("/medicines");
            }}
          >
            <div className="flex items-center justify-center flex-shrink-0 w-16 text-sm font-medium text-white bg-yellow-500 rounded-l-md">
              ML
            </div>
            <div className="flex items-center justify-between flex-1 truncate bg-white border-t border-b border-r border-gray-200 rounded-r-md">
              <div className="flex-1 px-4 py-2 text-sm truncate">
                <span className="font-medium text-gray-900 hover:text-gray-600">
                  {t("medicine_list")}
                </span>
                <p className="text-gray-500">{medicinesCount}</p>
              </div>
              <div className="flex-shrink-0 pr-2">
                <button
                  className="inline-flex items-center justify-center w-8 h-8 text-gray-400 bg-transparent bg-white rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() => {
                    navigate("/medicines");
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M13 7l5 5m0 0l-5 5m5-5H6"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </li>

          <li
            className="flex col-span-1 rounded-md shadow-sm cursor-pointer"
            onClick={() => {
              navigate("/");
            }}
          >
            <div className="flex items-center justify-center flex-shrink-0 w-16 text-sm font-medium text-white bg-green-500 rounded-l-md">
              MI
            </div>
            <div className="flex items-center justify-between flex-1 truncate bg-white border-t border-b border-r border-gray-200 rounded-r-md">
              <div className="flex-1 px-4 py-2 text-sm truncate">
                <span className="font-medium text-gray-900 hover:text-gray-600">
                  {t("this_months_income")}
                </span>
                <p className="text-gray-500">{thisMonthsIncomeCount}</p>
              </div>
              <div className="flex-shrink-0 pr-2">
                <button
                  className="inline-flex items-center justify-center w-8 h-8 text-gray-400 bg-transparent bg-white rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M13 7l5 5m0 0l-5 5m5-5H6"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div className="grid grid-cols-2 mt-2">
        <div className="py-2 bg-white border rounded-md ">
          {chartData && <BarChart data={chartData} />}
        </div>
        <div></div>
      </div>
    </>
  );
};
export default Dashboard;
