import React, { useEffect, useState } from "react";
import { VisitMedicines } from "../../interfaces/Visit";
import { formatString } from "../../utils/StringUtil";

type PrescriptionProps = {
  preview?: boolean;
  medicines?: Array<VisitMedicines>;
  advice?: string;
  prescription: Record<any, any>;
};
const Prescription: React.FC<PrescriptionProps> = ({
  preview,
  medicines,
  prescription,
  advice,
}) => {
  const [instructions, setInstructions] = useState<Array<string>>([]);

  useEffect(() => {
    if (advice) {
      const adviceJson = JSON.parse(advice);
      const data = adviceJson.blocks.map((i: Record<string, string>) => i.text);
      setInstructions(data);
    }
  }, [advice]);

  return (
    <>
      {prescription ? (
        <div className="flex flex-col max-w-4xl mx-auto bg-white border rounded-md prescription">
          <div className="p-4 border-b-2 basis-1/6 header">
            <div className="grid grid-cols-2">
              <div className="flex flex-col">
                <span className="text-base">{prescription?.clinicName}</span>
                <span className="text-sm capitalize">
                  {prescription?.clinicAddress}
                </span>
              </div>
              <div className="flex flex-col text-right">
                <span className="text-base">{prescription?.user.name}</span>
                <span className="text-sm">{prescription?.user.degree}</span>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 p-4 border-b-2 basis-1/5 sm:grid-cols-4">
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Name</dt>
              <dd className="mt-1 text-sm text-gray-900 capitalize">
                {prescription?.patient?.name}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Gender</dt>
              <dd className="mt-1 text-sm text-gray-900 capitalize">
                {prescription?.patient?.gender}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Age</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {prescription?.patient?.age}
              </dd>
            </div>

            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Phone</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {prescription?.patient?.phone}
              </dd>
            </div>
          </div>

          <div className="px-4 py-2 basis-1/2 body">
            <div className="flex flex-col">
              <img src="/rx.png" className="w-12" alt="" />
            </div>

            {medicines && medicines?.length > 0 && (
              <>
                <span className="font-semibold">Medicines: </span>
                <table className="w-full mt-2 border">
                  <thead className="border ">
                    <th className="font-semibold">Name</th>
                    <th className="font-semibold">Dosage</th>
                    <th className="font-semibold text-right">Notes</th>
                  </thead>
                  <tbody>
                    {medicines?.map((medicine, index) => (
                      <tr className="border-b">
                        <td className="capitalize">
                          {index + 1}
                          {") "}
                          {medicine.instructions.name}
                        </td>
                        <td>
                          {medicine.instructions.morning} -{" "}
                          {medicine.instructions.afternoon} -{" "}
                          {medicine.instructions.night}
                          <br />
                          {formatString(medicine.instructions.meal)}
                        </td>
                        <td className="text-right">
                          ----{medicine.instructions.duration}{" "}
                          <span className="capitalize ">
                            {medicine.instructions.durationPeriod}
                            <br />
                            {medicine.instructions.note}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            )}
            {instructions.length > 0 && (
              <div className="py-2 mt-2 ">
                <span className="font-semibold">Advice: </span>
                <ol className="pl-4 list-decimal">
                  {instructions.map((i) => (
                    <li>{i}</li>
                  ))}
                </ol>
              </div>
            )}
            <div className="grid grid-cols-1 p-2 sm:grid-cols-4"></div>
          </div>
          <div className="p-4 mt-auto basis-1/5 footer">
            <p className="float-right p-2">Sign</p>
          </div>
        </div>
      ) : (
        <div className="flex justify-center text-center">
          <span className="pt-20">Loading ...</span>
        </div>
      )}
    </>
  );
};

export default Prescription;
