import { Select } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { getPatients } from "../../apis/patient";
import { getUsers } from "../../apis/user";
import { saveVisit } from "../../apis/visit";
import { Patient } from "../../interfaces/Patient";
import { User } from "../../interfaces/User";
import { useNotification } from "../../NotificationContext";
import { LanguageContext } from "../../providers/LanguageProvider";
import { checkDoctorAvailability } from "../../apis/event";
import { format } from "date-fns";
import DatePicker from "react-datepicker";
import { getNextAppointmentTime } from "../../utils/DateUtil";

const AddAppointment: React.FC<{
  closeModal: () => void;
  patientId: any;
  userId?: number;
}> = ({ closeModal, patientId, userId }) => {
  const appointmentWindow = parseInt(
    window.localStorage.getItem("appointmentWindow") ?? "15"
  );

  const [visit, setVisit] = useState({
    date: getNextAppointmentTime(appointmentWindow),
    isInpatient: true,
    patientId: "",
    parentId: null,
    clinicUserId: "",
    clinicVisitTypeId: 1,
    assessment: {},
    userId: null,
    meta: {
      height: "",
      weight: "",
    },
    fee: 0,
    discount: 0,
    medicines: [],
    services: [],
    symptoms: [],
    provisionalDiagnosis: [],
    finalDiagnosis: [],
  });

  const { showNotification } = useNotification();
  const [patients, setPatients] = useState<Array<Patient>>([]);
  const [users, setUsers] = useState<Array<User>>([]);
  const Option = Select;
  const { translate: t } = useContext(LanguageContext);
  const [errors, setErrors] = useState<any>();
  const datetimeRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (patientId) {
      fetchPatients({ id: patientId });
      setVisit({ ...visit, patientId });
    } else {
      fetchPatients({});
    }
    fetchUsers({});
  }, []);

  const fetchPatients = (params: any) => {
    getPatients(params).then((res: any) => {
      setPatients(res.data.data);
    });
  };

  const fetchUsers = (params: any) => {
    getUsers(params).then((res: any) => {
      const users = res.data;
      const filteredUsers = users.filter((user: any) => user.role === "doctor");
      setUsers(filteredUsers);
    });
  };

  const createScheduleVisit = async () => {
    const { hasErrors, validationErrors } = validateForm();
    if (hasErrors) {
      setErrors(validationErrors);
      return;
    }
    setErrors(null);
    const result = await checkDoctorAvailability({
      userId: visit.userId,
      date: new Date(visit.date).toISOString(),
    });
    if (result.data.availability) {
      saveVisit({ ...visit, date: new Date(visit.date).toISOString() }).then(
        () => {
          showNotification(
            "success",
            "Appointment Added successfully",
            "",
            "topRight"
          );
          closeModal();
        }
      );
    } else {
      showNotification(
        "error",
        "Error",
        "Doctor is unavailable. Please select another date or time.",
        "topRight"
      );
    }

    return;
  };

  const validateForm = () => {
    const validationErrors: any = {};
    if (!visit?.userId) {
      validationErrors.userId = "* doctor name is required";
    }
    if (visit?.patientId === "") {
      validationErrors.patient = "* patient name is required";
    }

    if (!visit?.date || isNaN(new Date(visit.date).getTime())) {
      validationErrors.date = "* date is required";
    }

    const hasErrors = Boolean(Object.keys(validationErrors).length);

    return { hasErrors, validationErrors };
  };

  const handlePatientChange = (patientId: any) => {
    setVisit({
      ...visit,
      patientId,
    });
  };

  const handleUserChange = (userId: any) => {
    setVisit({
      ...visit,
      userId,
    });
  };

  const handleDateChange = (value: Date | null) => {
    if (value) {
      setVisit({
        ...visit,
        date: value,
      });
    }
  };

  return (
    <div className="">
      <span className="font-medium">{t("appointment")}</span>
      <div className="pt-2">
        <label className="block text-sm font-medium leading-6 text-gray-900">
          {t("patient_name")}
        </label>
        <div className="mt-1">
          <Select
            style={{ width: "100%" }}
            onChange={handlePatientChange}
            value={visit.patientId}
            disabled={patientId ? true : false}
          >
            {patients.map((patient) => (
              <Option key={patient.id} value={patient.id}>
                {patient.firstName + " " + patient.lastName}
              </Option>
            ))}
          </Select>
          {errors && errors.patient && (
            <span className="text-sm text-red-500 leading-0">
              {errors.patient}
            </span>
          )}
        </div>
      </div>
      <div className="pt-2">
        <label className="block text-sm font-medium leading-6 text-gray-900">
          {"Doctor Name"}
        </label>
        <div className="mt-1">
          <Select
            style={{ width: "100%" }}
            onChange={handleUserChange}
            value={visit.userId}
          >
            {users.map((user) => (
              <Option key={user.id} value={user.id}>
                {user.firstName + " " + user.lastName}
              </Option>
            ))}
          </Select>
          {errors && errors.clinicUserId && (
            <span className="text-sm text-red-500 leading-0">
              {errors.clinicUserId}
            </span>
          )}
        </div>
      </div>
      <div className="pt-2">
        <label className="block text-sm font-medium leading-6 text-gray-900">
          {t("date")}
        </label>
        <div className="mt-1">
          <DatePicker
            selected={new Date(visit.date)}
            onChange={handleDateChange}
            portalId="root-panel"
            showTimeSelect={true}
            timeFormat="HH:mm"
            timeIntervals={appointmentWindow}
            dateFormat="MMMM d, yyyy h:mm aa"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
          {errors && errors.date && (
            <span className="text-sm text-red-500 leading-0">
              {errors.date}
            </span>
          )}
        </div>
      </div>

      <div className="flex items-center justify-start py-4 gap-x-6 border-gray-900/10 ">
        <button
          onClick={() => createScheduleVisit()}
          type="submit"
          className="px-3 py-2 text-sm font-semibold text-white rounded-md shadow-sm bg-primary hover:bg-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-dark"
        >
          {t("save")}
        </button>
        <button
          onClick={() => closeModal()}
          type="button"
          className="text-sm font-semibold leading-6 text-gray-900"
        >
          {t("cancel")}
        </button>
      </div>
    </div>
  );
};

export default AddAppointment;
