import React, { useState } from "react";
import { getVisitFile, getVisitPrescription } from "../../apis/visit";
import Modal from "../../components/Modal";
import { BASE_URL } from "../../constants";
import PaymentSummary from "./PaymentSummary";

// Reusable Component for Displaying List of Values
const ListDisplay: React.FC<{ title: string; items: string[] }> = ({
  title,
  items,
}) => {
  return (
    <div className="sm:col-span-1">
      <dt className="text-base font-medium text-gray-500">{title}</dt>
      {items && items.length > 0 ? (
        items.map((item, index) => (
          <dd key={index} className="mt-1 text-sm text-gray-900">
            {item}
          </dd>
        ))
      ) : (
        <dd className="mt-1 text-sm text-gray-500">No data available</dd>
      )}
    </div>
  );
};

// Reusable Component for Displaying Key-Value Pairs
const KeyValueListDisplay: React.FC<{
  title: string;
  items: { label: string; value: string }[];
}> = ({ title, items }) => {
  return (
    <div className="sm:col-span-1">
      <dt className="text-base font-medium text-gray-500">{title}</dt>
      {items && items.length > 0 ? (
        items.map((item, index) => (
          <dd key={index} className="mt-1 text-sm text-gray-900">
            {item.label} : {item.value}
          </dd>
        ))
      ) : (
        <dd className="mt-1 text-sm text-gray-500">No data available</dd>
      )}
    </div>
  );
};
const PreviousVisitCard: React.FC<{ previousVisit: any }> = ({
  previousVisit,
}) => {
  const [isPaymentSummaryModalOpen, setPaymentSummaryModalVisibility] =
    useState<boolean>(false);
  const [visitId, setVisitId] = useState(null);

  const handleVisitPaymentSummary = (id: any) => {
    setVisitId(id);
    setPaymentSummaryModalVisibility(true);
  };

  const handleCloseModal = () => {
    setPaymentSummaryModalVisibility(false);
  };

  const viewDetailedVisit = (id: number) => {
    window.open(`/visits/${id}`);
  };

  const getPrescription = (id: number) => {
    getVisitPrescription(id).then((res: any) => {
      window.open(` ${BASE_URL}` + res.data.link);
    });
  };

  const viewAttachments = (url: string) => {
    getVisitFile({ file: url }).then((res: any) => {
      window.open(res.data);
    });
  };

  return (
    <div className="pt-2 lg:pt-5">
      <div className="absolute w-3 h-3 bg-primary rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
      <time className="mb-1 text-sm font-normal leading-none text-active dark:text-gray-500">
        {previousVisit.date}
      </time>
      <div className="overflow-hidden bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 border-t border-gray-200 sm:px-6">
          <div className="flex flex-row-reverse">
            <button onClick={() => viewDetailedVisit(previousVisit.id)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                />
              </svg>
            </button>
          </div>

          <dl className="grid grid-cols-1 lg:gap-x-4 gap-x-2 gap-y-3 lg:gap-y-8 lg:grid-cols-3">
            <div className="sm:col-span-1">
              <dt className="text-base font-medium text-gray-500">Symptoms</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {previousVisit.symptoms || "No data available"}
              </dd>
            </div>

            <KeyValueListDisplay
              title="Physical Examinations"
              items={previousVisit.physicalExaminations || []}
            />

            <ListDisplay
              title="Provisional Diagnosis"
              items={previousVisit.provisionalDiagnosis || []}
            />

            <ListDisplay
              title="Final Diagnosis"
              items={previousVisit.finalDiagnosis || []}
            />

            {/* Services Provided */}
            <div className="sm:col-span-1">
              <dt className="text-base font-medium text-gray-500">
                Services Provided
              </dt>
              {previousVisit.services && previousVisit.services.length > 0 ? (
                previousVisit.services.map((service: any, index: number) => (
                  <dd key={index} className="mt-1 text-sm text-gray-900">
                    {service.name} : {service.quantity}
                  </dd>
                ))
              ) : (
                <dd className="mt-1 text-sm text-gray-500">
                  No services provided
                </dd>
              )}
            </div>

            {/* Medicines Section */}
            <div className="sm:col-span-3">
              <dt className="text-base font-medium text-gray-500">
                Prescribed Medicines
                {previousVisit.medicines && previousVisit.medicines.length ? (
                  <button
                    onClick={() => getPrescription(previousVisit.id)}
                    type="button"
                    className="justify-center float-right text-sm font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    View
                  </button>
                ) : (
                  <div className="pt-2 text-sm font-medium text-gray-600">
                    No medicines Added
                  </div>
                )}
              </dt>
              {previousVisit.medicines &&
                previousVisit.medicines.length > 0 && (
                  <dd className="mt-1 text-sm text-gray-900">
                    <table className="min-w-full border divide-y divide-gray-300 rounded-md">
                      <thead className="bg-gray-50">
                        <tr>
                          <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                            Name
                          </th>
                          <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Dosage
                          </th>
                          <th className=" px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Qty.
                          </th>
                          <th className=" px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Note
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {previousVisit.medicines.map(
                          (medicine: any, index: number) => (
                            <tr key={index}>
                              <td className="py-2 pl-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap sm:pl-6">
                                {medicine.name}
                              </td>
                              <td className="px-3 py-2 text-sm text-gray-500 whitespace-nowrap">
                                {medicine.morning || "0"}--
                                {medicine.afternoon || "0"}--
                                {medicine.night || "0"} (
                                {medicine.meal === "before_meal"
                                  ? "Before Meal"
                                  : "After Meal"}
                                )
                              </td>
                              <td className="hidden px-3 py-2 text-sm text-gray-500 lg:block whitespace-nowrap">
                                {medicine.duration} {medicine.durationPeriod}
                              </td>
                              <td className="hidden px-3 py-2 text-sm text-gray-500 lg:block whitespace-nowrap">
                                {medicine.note}
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </dd>
                )}
            </div>

            {/* Attachments Section */}
            <div className="sm:col-span-3">
              <dt className="text-base font-medium text-gray-500">
                Attachments
              </dt>
              {previousVisit.attachments &&
              previousVisit.attachments.length > 0 ? (
                <ul>
                  {previousVisit.attachments.map(
                    (attachment: any, index: number) => (
                      <li key={index} className="mt-2">
                        <button
                          type="button"
                          onClick={() => viewAttachments(attachment.file)}
                          className="underline text-primary hover:text-blue-600"
                        >
                          {attachment.name}
                        </button>
                      </li>
                    )
                  )}
                </ul>
              ) : (
                <dd className="mt-1 text-sm text-gray-500">
                  No attachments available
                </dd>
              )}
            </div>

            {/* Payment Summary Button */}
            <div className="sm:col-span-3">
              <button
                type="button"
                onClick={() => handleVisitPaymentSummary(previousVisit.id)}
                className="inline-flex items-center px-4 py-2 mt-4 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-primary hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                View Payment Summary
              </button>
            </div>
          </dl>
        </div>
      </div>

      {/* Payment Summary Modal */}
      {isPaymentSummaryModalOpen && (
        <Modal
          modalContent={
            <PaymentSummary
              visitId={visitId}
              closeModal={() => handleCloseModal()}
            />
          }
          onClose={handleCloseModal}
        ></Modal>
      )}
    </div>
  );
};

export default PreviousVisitCard;
