import { DatePicker, Space } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Filter } from "../interfaces/Filter";
import { useNotification } from "../NotificationContext";
import { LanguageContext } from "../providers/LanguageProvider";
const { RangePicker } = DatePicker;
function classNames(...classes: Array<string>) {
  return classes.filter(Boolean).join(" ");
}

const Filters: React.FC<{
  filters: Array<Filter>;
  fetchList: (filter: Array<Filter>) => void;
  clearFilterList: boolean;
}> = ({ filters, fetchList, clearFilterList }) => {
  const [isFilterVisible, setFiltersVisibility] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState<Array<Filter>>([]);
  const [currentFilter, setCurrentFilter] = useState<Filter | null>();
  const [currentFilterVisibility, setCurrentFilterVisiblity] = useState(false);
  const { search } = useLocation();
  const { showNotification } = useNotification();
  const { translate: t } = useContext(LanguageContext);
  const filterRef = useRef(null);

  useEffect(() => {
    if (clearFilterList === true) {
      setSelectedFilters([]);
      fetchList([]);
    }
  }, [clearFilterList]);

  useEffect(() => {
    if (search) {
      const filters = JSON.parse(
        '{"' +
          decodeURI(search.substring(1))
            .replace(/\+/g, " ")
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"') +
          '"}'
      );
      if (Object.keys(filters).length > 0) {
        let list: Array<Filter> = [];
        Object.keys(filters).forEach((i) => {
          if (i !== "type") {
            list.push({
              label: i.replace(/_/g, " "),
              value: filters[i],
              key: i,
              component: "input",
            });
          }
        });

        setSelectedFilters(list);
      }
    }
  }, [search]);

  const setValue = (value: string) => {
    if (currentFilter?.label) {
      setCurrentFilter({
        value,
        label: currentFilter?.label,
        key: currentFilter?.key,
        component: "input",
      });
      setCurrentFilterVisiblity(true);
    }
  };

  const setDateValue = (_: any, dates: Array<any>) => {
    if (dates.length === 2) {
      if (currentFilter?.label) {
        setCurrentFilter({
          value: `${dates[0]}to${dates[1]}`,
          label: currentFilter?.label,
          key: currentFilter?.key,
          component: "date",
        });
      }
    }
  };

  const handleFilterApply = () => {
    if (currentFilter) {
      if (currentFilter.value.length > 0) {
        const list = [...selectedFilters, currentFilter];
        setSelectedFilters(list);
        fetchList(list);
      } else {
        showNotification(
          "error",
          "Error Occurred",
          "Value cannot be empty",
          "topRight"
        );
      }
    }
    setFiltersVisibility(false);
    setCurrentFilter(null);
  };

  const handleFilterSelect = (i: Filter) => {
    setCurrentFilter(i);
    setCurrentFilterVisiblity(true);
  };

  const handleCancel = () => {
    setFiltersVisibility(false);
    setCurrentFilter(null);
  };

  const handleFilterRemove = (item: Filter) => {
    const list = selectedFilters.filter((i) => i.key !== item.key);
    setSelectedFilters(list);
    fetchList(list);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      filterRef.current &&
      !(filterRef.current as any).contains(event.target)
    ) {
      setFiltersVisibility(false);
      setCurrentFilterVisiblity(false);
    }
  };

  useEffect(() => {
    if (isFilterVisible || currentFilterVisibility) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isFilterVisible, currentFilterVisibility]);

  return (
    <>
      <div className="relative flex justify-end">
        <div className="hidden pr-2 mr-20 lg:flex">
          {selectedFilters.length > 0 &&
            selectedFilters.map((i, index) => (
              <span
                key={index}
                className="inline-flex mr-2 items-center rounded bg-indigo-100 py-0.5 pl-2 pr-0.5 text-xs font-medium text-indigo-700"
              >
                {i.label} : {i.value}
                <button
                  onClick={() => handleFilterRemove(i)}
                  type="button"
                  className="ml-0.5 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500 focus:bg-indigo-500 focus:text-white focus:outline-none"
                >
                  <svg
                    className="w-2 h-2"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 8 8"
                  >
                    <path
                      strokeLinecap="round"
                      strokeWidth="1.5"
                      d="M1 1l6 6m0-6L1 7"
                    />
                  </svg>
                </button>
              </span>
            ))}
        </div>

        <div className="absolute inline-block text-left " ref={filterRef}>
          {/* mobile screen button */}
          <button
            type="button"
            className="lg:hidden inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold shadow-sm hover:bg-hover"
            id="menu-button"
            onClick={() => setFiltersVisibility(!isFilterVisible)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-4 h-4 text-white"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 0 1-.659 1.591l-5.432 5.432a2.25 2.25 0 0 0-.659 1.591v2.927a2.25 2.25 0 0 1-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 0 0-.659-1.591L3.659 7.409A2.25 2.25 0 0 1 3 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0 1 12 3Z"
              />
            </svg>
          </button>

          {/* large screen button */}
          <button
            type="button"
            className="hidden lg:flex  w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-black shadow-sm ring-1 ring-inset ring-gray-300 "
            id="menu-button"
            aria-expanded="true"
            aria-haspopup="true"
            onClick={() => setFiltersVisibility(!isFilterVisible)}
          >
            {t("filters")}
            <svg
              className="w-5 h-5 -mr-1 text-black"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                clipRule="evenodd"
              />
            </svg>
          </button>
          {isFilterVisible && (
            <div
              className="absolute relative right-0 z-20 mt-2 origin-top-right bg-white rounded-md shadow-lg lg:w-36 ring-1 ring-black ring-opacity-5 focus:outline-none"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="menu-button"
            >
              <div className="py-1" role="none">
                {filters.map((i, index) => (
                  <span
                    key={index}
                    onClick={() => handleFilterSelect(i)}
                    className={classNames(
                      currentFilter && currentFilter.label === i.label
                        ? "text-gray-900 bg-gray-100"
                        : "text-gray-700 ",
                      "block px-4 py-2 text-sm cursor-pointer"
                    )}
                  >
                    {i.label}
                  </span>
                ))}
              </div>
            </div>
          )}
          {currentFilterVisibility && currentFilter && (
            <div className="absolute z-20 w-56 mt-2 bg-white rounded-md shadow-lg top-10 right-10 lg:right-40 ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="block px-4 py-2">
                <label className="text-sm text-gray-700">
                  {currentFilter.label}
                </label>
                {currentFilter.component === "input" && (
                  <input
                    type="text"
                    className="block w-full border-gray-300 rounded-md focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    onChange={(e) => setValue(e.target.value)}
                  />
                )}
                {currentFilter.component === "date" && (
                  <Space direction="vertical" size={12}>
                    <RangePicker
                      onChange={setDateValue}
                      getPopupContainer={(trigger: HTMLElement) =>
                        trigger.parentNode as HTMLElement
                      }
                    />
                  </Space>
                )}

                <div className="flex gap-4 pt-5">
                  <button
                    onClick={() => handleFilterApply()}
                    className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-primary text-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-hover"
                  >
                    {t("apply")}
                  </button>
                  <button
                    onClick={() => handleCancel()}
                    className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  >
                    {t("cancel")}
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="flex justify-end w-full pt-12 lg:hidden">
        {selectedFilters.length > 0 &&
          selectedFilters.map((i, index) => (
            <span
              key={index}
              className="inline-flex mr-2 items-center rounded bg-indigo-100 py-0.5 pl-2 pr-0.5 text-xs font-medium text-indigo-700"
            >
              {i.label} : {i.value}
              <button
                onClick={() => handleFilterRemove(i)}
                type="button"
                className="ml-0.5 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500 focus:bg-indigo-500 focus:text-white focus:outline-none"
              >
                <svg
                  className="w-2 h-2"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 8 8"
                >
                  <path
                    strokeLinecap="round"
                    strokeWidth="1.5"
                    d="M1 1l6 6m0-6L1 7"
                  />
                </svg>
              </button>
            </span>
          ))}
      </div>
    </>
  );
};

export default Filters;
