import React, { useEffect, useState } from "react";
import { Select, Upload, message } from "antd";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  UpdateVisitField,
  changeVisitData,
  selectVisit,
} from "../../features/visit/visitSlice";
import { Visit } from "../../interfaces/Visit";
import { addSymptom, getSymptoms } from "../../apis/symptom";
import { Symptom } from "../../interfaces/Symptom";
import { getDisorders, saveDisorder } from "../../apis/disorder";
import { Disorder } from "../../interfaces/Disorder";
import { useParams } from "react-router-dom";
import { getServices } from "../../apis/service";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import {
  RcFile,
  UploadChangeParam,
  UploadFile,
  UploadProps,
} from "antd/es/upload";
import { saveVisitFiles } from "../../apis/visit";
const Diagnosis = () => {
  const visit = useAppSelector(selectVisit);
  const params = useParams();
  const dispatch = useAppDispatch();
  const [symptoms, setSymptoms] = useState<Array<Symptom>>([]);
  const [disorders, setDisorders] = useState<Array<Disorder>>([]);
  const [loading, setLoading] = useState(false);
  const [fileUrl, setFileUrl] = useState<string | null>();
  const [, setVisitFile] = useState<any>();

  useEffect(() => {
    fetchSymptoms();
    fetchDisorders();
  }, []);

  useEffect(() => {
    if (params.patientId) {
      fetchDefaultServices();
    }
  }, [params]);

  const fetchDefaultServices = () => {
    getServices({ is_default_selected: true, per_page: 100 }).then(
      (res: any) => {
        const services = res.data.data.map((i: any) => {
          return {
            id: i.id,
            fee: i.fee,
            quantity: 1,
          };
        });
        updateField("services", services);
      }
    );
  };
  const updateField = (fieldName: keyof Visit, value: any) => {
    const payload: UpdateVisitField = { fieldName, value };
    dispatch(changeVisitData(payload));
  };

  const fetchSymptoms = () =>
    getSymptoms({}).then((res: any) => {
      setSymptoms(res.data.data);
    });

  const fetchDisorders = async () => {
    getDisorders({}).then((res: any) => {
      setDisorders(res.data.data);
    });
  };

  /*----------------------------symptom's functions------------------------------------*/

  const handleSymptomChange = (value: any) => {
    if (value && value.length > 0) {
      let selectedSymptoms: Array<number> = visit.symptoms;

      const latestElement = value.slice(-1)[0];
      if (typeof latestElement !== "number") {
        addNewSymptom(latestElement, "symptoms");
      } else {
        const [ifExists] = selectedSymptoms.filter((i) => i === latestElement);
        if (ifExists) {
          selectedSymptoms = value;
        } else {
          selectedSymptoms = [...selectedSymptoms, latestElement];
        }
      }
      updateField("symptoms", selectedSymptoms);
    } else {
      updateField("symptoms", []);
    }
  };

  const addNewSymptom = (name: string, visitDataKey: keyof Visit) => {
    addSymptom({ name }).then((res: any) => {
      const exisitingSymptoms: Array<number> = visit.symptoms || [];
      fetchSymptoms();
      updateField("symptoms", [...exisitingSymptoms, res.id]);
    });
  };

  /*----------------------------provisional diagnosis's functions------------------------------------*/

  const handleProvisionalDiagnosisChange = (value: any) => {
    if (value && value.length > 0) {
      let selectedDisorders: Array<number> = visit.provisionalDiagnosis;
      const latestElement = value.slice(-1)[0];
      if (typeof latestElement !== "number") {
        addNewProvisionalDignosis(latestElement, "disease");
      } else {
        const [ifExists] = selectedDisorders.filter((i) => i === latestElement);
        if (ifExists) {
          selectedDisorders = value;
        } else {
          selectedDisorders = [...selectedDisorders, latestElement];
        }
      }
      updateField("provisionalDiagnosis", selectedDisorders);
    } else {
      updateField("provisionalDiagnosis", []);
    }
  };

  const addNewProvisionalDignosis = async (name: string, type: string) => {
    await saveDisorder({ name, type }).then(async (res: any) => {
      await fetchDisorders();
      const exisitingDiseases: Array<number> = visit.provisionalDiagnosis || [];
      updateField("provisionalDiagnosis", [...exisitingDiseases, res.data.id]);
    });
  };

  /*----------------------------final diagnosis's functions------------------------------------*/

  const handleFinalDiagnosisChange = (value: any) => {
    if (value && value.length > 0) {
      let selectedDisorders: Array<number> = visit.finalDiagnosis;
      const latestElement = value.slice(-1)[0];
      if (typeof latestElement !== "number") {
        addNewFinalDignosis(latestElement, "disease");
      } else {
        const [ifExists] = selectedDisorders.filter((i) => i === latestElement);
        if (ifExists) {
          selectedDisorders = value;
        } else {
          selectedDisorders = [...selectedDisorders, latestElement];
        }
      }
      updateField("finalDiagnosis", selectedDisorders);
    } else {
      updateField("finalDiagnosis", []);
    }
  };

  const addNewFinalDignosis = async (name: string, type: string) => {
    await saveDisorder({ name, type }).then(async (res: any) => {
      await fetchDisorders();
      const exisitingDiseases: Array<number> = visit.finalDiagnosis || [];
      updateField("finalDiagnosis", [...exisitingDiseases, res.data.id]);
    });
  };

  const customFileSetter = (arg: any) => {
    setVisitFile(arg.file);
  };

  const beforeUploadingFile = (file: RcFile) => {
    let isValid = true;
    const isPDF = file.type === "application/pdf";
    const isPNG = file.type === "image/png";
    const isJPG = file.type === "image/jpeg";

    const isAcceptedFileType = isPDF || isPNG || isJPG;

    if (!isAcceptedFileType) {
      isValid = false;

      message.error("You can only upload PDF, PNG, or JPG files!");
    }

    if (!isValid) {
      return Upload.LIST_IGNORE;
    }
    return isAcceptedFileType;
  };

  const handleFileChange: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    setLoading(true);
    setVisitFile(info.file);
    uploadReports(info.file.originFileObj as RcFile);
    setFileUrl(info.file.name);
    setLoading(false);
  };

  const UploadFileButton = () => (
    <div className="cursor-pointer">
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8, width: 100 }}>Upload</div>
    </div>
  );

  const getFileName = (file: string) => {
    return file.split("/").pop();
  };

  const uploadReports = (file: any) => {
    saveVisitFiles({ file: file })
      .then((res: any) => {
        const files = [...visit.visitFiles, res.url];
        updateField("visitFiles", files);
        message.success("Files uploaded successfully!");
      })
      .catch(() => {
        message.error("Failed to upload files!");
      });
  };

  return (
    <>
      <div className="grid w-full gap-2 p-2 lg:gap-4 lg:p-4 lg:grid-cols-2">
        <div className="block col-span-2">
          <span>Physical Examination </span>
        </div>
        <div className="relative grid grid-cols-2 gap-2 py-3 bg-white rounded-lg lg:px-6 lg:gap-4 lg:py-5 lg:border lg:border-gray-300 lg:shadow-md focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400">
          <div className="block col-span-2">
            <span className="text-sm font-medium text-gray-700">Symptoms</span>
            <Select
              mode="tags"
              style={{ width: "100%" }}
              placeholder="Enter symptoms"
              onChange={handleSymptomChange}
              value={visit.symptoms}
              options={symptoms.map((i) => ({ value: i.id, label: i.name }))}
            />
          </div>
          <div className="block">
            <span className="text-sm font-medium text-gray-700">
              Provisional Diagnosis
            </span>

            <Select
              mode="tags"
              style={{ width: "100%" }}
              placeholder="Enter provisional diagnosis"
              onChange={handleProvisionalDiagnosisChange}
              value={visit.provisionalDiagnosis}
              options={disorders.map((i) => ({ value: i.id, label: i.name }))}
            />
          </div>
          <div className="block">
            <span className="text-sm font-medium text-gray-700">
              Final Diagnosis
            </span>

            <Select
              mode="tags"
              style={{ width: "100%" }}
              placeholder="Enter final diagnosis"
              onChange={handleFinalDiagnosisChange}
              value={visit.finalDiagnosis}
              allowClear={true}
              options={disorders.map((i) => ({ value: i.id, label: i.name }))}
            />
          </div>
        </div>

        <div className="relative grid items-center grid-cols-2 gap-2 py-3 bg-white rounded-lg lg:gap-4 lg:px-6 lg:py-5 lg:border lg:border-gray-300 lg:shadow-md focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400">
          <div className="block">
            <span className="text-sm font-medium text-gray-700">Reports</span>

            <div className="mt-2 sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:border-gray-200">
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <Upload
                  name="file"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  beforeUpload={beforeUploadingFile}
                  onChange={handleFileChange}
                  customRequest={customFileSetter}
                  multiple={true}
                >
                  <UploadFileButton />
                </Upload>
              </div>
            </div>
          </div>
          {visit.visitFiles && visit.visitFiles.length > 0 && (
            <div className="flex-grow block col-span-2">
              <span className="text-sm font-medium text-gray-700">
                Attached Reports
              </span>
              <div className="grid grid-cols-2 mt-2">
                {visit.visitFiles &&
                  visit.visitFiles?.map((file: any) => (
                    <span className="m-1">{file?.name}</span>
                  ))}
              </div>
              {fileUrl && (
                <span className="text-sm text-gray-500">
                  {getFileName(fileUrl)}
                </span>
              )}{" "}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Diagnosis;
