import { useParams } from "react-router-dom";
import PreviousVisitCard from "./PreviousVisitCard";
import { useEffect, useState } from "react";
import { getCompletedVisits, getVisit } from "../../apis/visit";
import { Visit } from "../../interfaces/Visit";

const ViewCompletedVisits = () => {
  const [completedVisits, setCompletedVisit] = useState<Array<Visit>>([]);
  const [visit, setVisit] = useState<Visit>();

  const params = useParams();

  useEffect(() => {
    if (params.visitId) {
      fetchVisit(params.visitId);
    }
  }, [params]);

  const fetchCompletedVisit = (id: any) => {
    getCompletedVisits(id).then((res: any) => {
      setCompletedVisit(res.data.data);
    });
  };

  const fetchVisit = (id: any) => {
    getVisit(id).then((res: any) => {
      setVisit(res);
      if (res.id) {
        fetchCompletedVisit(res.id);
      }
    });
  };
  return (
    <>
      <div className="flex flex-col flex-grow">
        <main className="flex flex-col flex-grow bg-[#ECEFFF] rounded-md">
          <div className="p-4">
            <ol className="relative border-l-2 border-indigo-300 dark:border-gray-700">
              <li className="mb-4 ml-4">
                <div className="absolute w-3 h-3 bg-primary rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
                <time className="mb-1 text-sm font-normal leading-none text-active dark:text-gray-500">
                  Patient Info
                </time>
                <div className="grid grid-cols-3 gap-4 p-4 mb-5 bg-white rounded">
                  <div className="flex w-full">
                    <span className="font-medium text-gray-900">
                      Patient Name:{" "}
                    </span>
                    <span className="ml-2 text-gray-800">
                      {visit?.patient?.firstName} {visit?.patient?.lastName}
                    </span>
                  </div>
                  <div className="flex w-full">
                    <span className="font-medium text-gray-900">
                      Reg. No.:{" "}
                    </span>
                    <span className="ml-2 text-gray-800">
                      {visit?.patient?.registrationNumber}
                    </span>
                  </div>
                  <div className="flex w-full">
                    <span className="font-medium text-gray-900">Phone: </span>
                    <span className="ml-2 text-gray-800">
                      {visit?.patient?.phone}
                    </span>
                  </div>
                  <div className="flex w-full">
                    <span className="font-medium text-gray-900">
                      Visit Type:{" "}
                    </span>
                    <span className="ml-2 text-gray-800 capitalize">
                      {visit?.clinicVisitType?.visitType.name}
                    </span>
                  </div>
                </div>
              </li>
              {completedVisits &&
                completedVisits.length > 0 &&
                completedVisits.map((i, index: number) => (
                  <li className="ml-4" key={index}>
                    <PreviousVisitCard previousVisit={i} />
                  </li>
                ))}
            </ol>
          </div>
        </main>
      </div>
    </>
  );
};

export default ViewCompletedVisits;
