import Counter from "./components/Counter";
import Board from "./components/Board";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Layout from "./components/Layout";
import MedicineList from "./views/medicines/MedicineList";
import { Login } from "./views/Login";
import SymptomList from "./views/symptoms/SymptomList";
import UserList from "./views/users/UserList";
import Dashboard from "./views/dashboard/Dashboard";
import CompanyList from "./views/companies/CompanyList";
import DisorderList from "./views/disorder/DisorderList";
import ExpenseCategoryList from "./views/expense-categories/ExpenseCategoryList";
import ExpenseList from "./views/expenses/ExpenseList";
import Logs from "./views/logs/Logs";
import RolesAndPermissionsList from "./views/roles-and-permissions/RolesAndPermissionsList";
import AddPatient from "./views/patients/AddPatient";
import PatientList from "./views/patients/PatientList";
import LayoutDesign from "./views/layoutDesign/LayoutDesign";
import AddUser from "./views/users/AddUser";
import ServiceList from "./views/services/ServiceList";
import VisitList from "./views/visits/VisitList";
import VisitTimeline from "./views/visits/VisitTimeline";
import VisitRegisterList from "./views/visit-register/VisitRegisterList";
import Calendar from "./views/calender/Calender";
import LanguageProvider from "./providers/LanguageProvider";
import VisitTypes from "./views/visits/VisitTypes";
import PageNotFound from "./views/404";
import { loginLoader, userLoader } from "./utils/RouteLoader";
import SettingsList from "./views/settings/SettingsList";
import VisitTransactionList from "./views/visits/VisitTransactionList";
// import ResetPassword from "./views/ResetPassword";
import ResetPassword from "./views/ResetPassword";
import ViewCompletedVisits from "./views/visits/ViewCompletedVisits";
import Prescription from "./views/visits/Prescription";
import Profile from "./views/Profile";
import PatientAppointment from "./views/PatientAppointment";
import VisitTypeList from "./views/visit-types/VisitTypeList";
import BookedAppointment from "./views/BookedAppointment";

const App = () => {
  const router = createBrowserRouter([
    {
      path: "/login",
      element: <Login />,
      errorElement: <PageNotFound />,
      loader: loginLoader,
    },

    {
      path: "/auth/reset-password",
      element: <ResetPassword />,
    },
    {
      path: "/appointment",
      element: <PatientAppointment />,
    },
    {
      path: "/booked-appointment/:visitId",
      element: <BookedAppointment />,
    },
    {
      path: "/",
      element: <Layout />,
      errorElement: <PageNotFound />,
      loader: userLoader,
      children: [
        {
          path: "/layout-design",
          element: <LayoutDesign />,
        },
        {
          path: "/patients/new",
          element: <AddPatient />,
        },
        {
          path: "/patients/:patientId",
          element: <AddPatient />,
        },
        {
          path: "/patients",
          element: <PatientList />,
        },
        {
          path: "/medicines",
          element: <MedicineList />,
        },
        {
          path: "/companies",
          element: <CompanyList />,
        },
        {
          path: "/users",
          element: <UserList />,
        },
        {
          path: "/users/new",
          element: <AddUser />,
        },
        {
          path: "/users/:userId",
          element: <AddUser />,
        },
        {
          path: "/symptoms",
          element: <SymptomList />,
        },
        {
          path: "/dashboard",
          element: <Dashboard />,
        },
        {
          path: "/disorders",
          element: <DisorderList />,
        },
        {
          path: "/expense-categories",
          element: <ExpenseCategoryList />,
        },
        {
          path: "/expenses",
          element: <ExpenseList />,
        },
        {
          path: "/services",
          element: <ServiceList />,
        },
        {
          path: "/logs",
          element: <Logs />,
        },
        {
          path: "/roles-and-permissions",
          element: <RolesAndPermissionsList />,
        },
        {
          path: "/visits",
          element: <VisitList />,
        },
        {
          path: "/patients/:patientId/visits/types",
          element: <VisitTypes />,
        },
        {
          path: "/visits/:visitId",
          element: <VisitTimeline />,
        },
        {
          path: "/view/visits/:visitId",
          element: <ViewCompletedVisits />,
        },
        {
          path: "/visits/types/:typeId/patients/:patientId",
          element: <VisitTimeline />,
        },
        {
          path: "/visit-register",
          element: <VisitRegisterList />,
        },
        {
          path: "/calendar",
          element: <Calendar />,
        },
        {
          path: "/settings",
          element: <SettingsList />,
        },
        {
          path: "/transactions/visits/:visitId",
          element: <VisitTransactionList />,
        },
        // {
        //   path: "/prescription/visits/:visitId",
        //   element: <Prescription />,
        // },
        {
          path: "/profile/:userId",
          element: <Profile />,
        },
        {
          path: "/visit-types",
          element: <VisitTypeList />,
        },
      ],
    },
    {
      path: "/board",
      element: <Board />,
    },

    {
      path: "/counter",
      element: <Counter />,
    },
  ]);
  return (
    <LanguageProvider>
      <RouterProvider router={router}></RouterProvider>
    </LanguageProvider>
  );
};

export default App;
