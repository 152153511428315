import React, { useEffect, useState } from "react";
import { Visit, VisitService } from "../../interfaces/Visit";
import {
  UpdateVisitField,
  changeVisit,
  changeVisitData,
  selectVisit,
} from "../../features/visit/visitSlice";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getPaymentSummary, updateVisit } from "../../apis/visit";
import { useNotification } from "../../NotificationContext";
const Payment: React.FC<{
  preview?: boolean;
}> = ({ preview = false }) => {
  const [paymentSummary, setPaymentSummary] = useState<any>([]);
  const visit = useAppSelector(selectVisit);
  const dispatch = useAppDispatch();
  const { showNotification } = useNotification();

  useEffect(() => {
    if (!preview) {
      fetchSummary();
    }
  }, []);

  useEffect(() => {
    if (paymentSummary.total !== undefined) {
      updateField(
        "amountPaid",
        paymentSummary.total - (paymentSummary.discount ?? 0)
      );
    }
  }, [paymentSummary]);

  useEffect(() => {
    if (paymentSummary.total !== undefined) {
      updateField("amountPaid", paymentSummary.total - (visit.discount ?? 0));
    }
  }, [visit.discount]);

  const updateField = (fieldName: keyof Visit, value: any) => {
    const payload: UpdateVisitField = { fieldName, value };
    dispatch(changeVisitData(payload));
  };

  const fetchSummary = () => {
    getPaymentSummary(visit.id).then((res: any) => {
      setPaymentSummary(res.data.data);
    });
  };

  const getTotal = () => {
    const sum = visit.services.reduce((a, b) => {
      const fee = b.fee ?? 0;
      const quantity = b.quantity ?? 0;
      return a + fee * quantity;
    }, 0);
    return sum;
  };

  const handleAddQuantity = (service: VisitService) => {
    let visitServices: Array<VisitService> = visit.services;
    let updatedServices: Array<VisitService> = visitServices.map((i) => {
      if (i.id === service.id) {
        return { ...i, quantity: i.quantity + 1 };
      }
      return i;
    });

    updateField("services", updatedServices);
    updateVisitData({ ...visit, services: updatedServices });
  };

  const handleSubtractQuantity = (service: VisitService) => {
    let visitServices: Array<VisitService> = visit.services;

    let updatedServices: Array<VisitService> = visitServices.map((i) => {
      if (i.id === service.id) {
        if (i.quantity >= 1) {
          return { ...i, quantity: i.quantity - 1 };
        } else {
          showNotification(
            "error",
            "Quantity cannot be negative",
            "",
            "topRight"
          );
        }
      }
      return i;
    });

    updateField("services", updatedServices);
    updateVisitData({ ...visit, services: updatedServices });
  };

  const updateVisitData = async (payload: any) => {
    updateVisit(payload).then((res: any) => {
      dispatch(changeVisit(res));
      fetchSummary();
    });
  };

  const handleDiscount = (discount: any) => {
    updateField("discount", discount);
    updateVisitData({ ...visit, discount: discount });
  };

  const handlePaying = (amount: any) => {
    updateField("amountPaid", amount);
    updateVisitData({ ...visit, amountPaid: amount });
  };

  return (
    <div className="grid w-full gap-4 p-4 py-6 lg:grid-cols-2 gap-y-8">
      <div className="grid gap-4">
        <div className="relative grid gap-3 px-2 py-3 bg-white rounded-lg lg:px-6 lg:py-5 lg:space-x-3 lg:border lg:border-gray-300 lg:shadow-md focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400">
          <label className="text-lg">Fee</label>
          <table>
            <thead>
              <tr>
                <th>Description</th>
                <th>Qty</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {visit.services &&
                visit.services.map((service) => (
                  <tr key={service.id}>
                    <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap sm:pl-6">
                      {service.name}
                    </td>
                    <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap sm:pl-6">
                      <div className="flex">
                        <button
                          onClick={() => {
                            handleSubtractQuantity(service);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>
                        </button>

                        <span className="px-2"> {service.quantity}</span>

                        <button
                          onClick={() => {
                            handleAddQuantity(service);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>
                        </button>
                      </div>
                    </td>
                    <td>₹{(service?.fee ?? 0) * (service?.quantity ?? 0)}</td>
                  </tr>
                ))}
              <tr>
                <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap sm:pl-6">
                  Total
                </td>
                <td></td>
                <td>₹{getTotal()}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="relative grid items-center gap-2 py-3 bg-white rounded-lg lg:px-6 lg:grid-cols-2 lg:gap-4 lg:py-5 lg:space-x-2 lg:border lg:shadow-md focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400">
          <div className="block">
            <span className="text-lg">Discount</span>
            <input
              type="text"
              id="discount"
              value={visit.discount}
              onChange={(e) => handleDiscount(e.target.value)}
              className="block w-full mt-1 capitalize border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 sm:text-sm"
            />
          </div>
          <div className="block">
            <span className="text-lg">Paying</span>
            <input
              type="text"
              name="paying"
              value={visit.amountPaid}
              defaultValue={0}
              onChange={(e) => handlePaying(e.target.value)}
              className="block w-full mt-1 capitalize border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 sm:text-sm"
            />
          </div>
        </div>
      </div>
      <div className="grid gap-2 lg:gap-4">
        <div className="gap-4 px-2 py-2 pt-3 bg-white border border-gray-300 rounded-lg lg:px-6 lg:shadow-md lg:py-5 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400">
          <h3 className="text-lg">Payment Summary</h3>
          <div className="grid grid-cols-2 py-1">
            <span>Total Fee </span>
            <span>₹ {paymentSummary.total}</span>
          </div>
          <div className="grid grid-cols-2 py-0">
            <span>Discount </span>
            <span className="text-indigo-700">₹{visit.discount}</span>
          </div>
          <div className="grid grid-cols-2 py-0">
            <span className="py-1">Amount To Be Paid</span>
            <span>₹{paymentSummary.total - visit.discount} </span>
          </div>

          <div className="grid grid-cols-2 py-0">
            <span className="py-1">Paying</span>
            <span className="text-green-700">
              {visit.amountPaid ? visit.amountPaid : 0}
            </span>
          </div>

          <div className="grid grid-cols-2 py-0">
            <span className="py-1">Amount Due </span>
            <span className="text-red-700">
              ₹
              {paymentSummary.total
                ? paymentSummary.total -
                  (visit.discount ? visit.discount : 0) -
                  (visit.amountPaid ? visit.amountPaid : 0)
                : 0}
            </span>
            {/* <span className="text-red-700">₹{paymentSummary.amountDue }</span> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payment;
