import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getBookedAppointment } from "../apis/visit";

const BookedAppointment = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [appointmentDetails, setAppointmentDetails] = useState<any>();

  useEffect(() => {
    fetchBookedAppointment(params.visitId);
  }, []);

  const fetchBookedAppointment = (id: any) => {
    getBookedAppointment(id).then((res: any) => {
      setAppointmentDetails(res);
    });
  };

  return (
    <>
      <div className="px-4 my-10 sm:px-10">
        <div className="box-border mx-auto border-2 rounded-md max-w-7xl">
          <div className="flex p-6 m-0 border-b border-gray-200 rounded-md bg-primary">
            <h3 className="text-lg font-medium leading-6 text-white">
              Appointment booked successfully
            </h3>
          </div>
          <div className="ml-6 mt-2">
            <span className="font-medium"> Appointment Details</span>
            <div className="px-2 pt-10 sm:px-6 lg:px-8">
                <dl className="divide-y divide-gray-100">
                  <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-medium leading-6 text-gray-900">
                      Patient Name : 
                    </dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      {appointmentDetails?.patient?.firstName +
                        " " +
                        appointmentDetails?.patient?.lastName}
                    </dd>
                  </div>
                  <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-medium leading-6 text-gray-900">
                      Date : 
                    </dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      {appointmentDetails?.appointmentDate}
                    </dd>
                  </div>

                  <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-medium leading-6 text-gray-900">
                      Doctor Name :
                    </dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      {appointmentDetails?.user?.firstName +
                        " " +
                        appointmentDetails?.user?.lastName}
                    </dd>
                  </div>
                </dl>
              
              <div className="py-5">
                <div className="flex justify-start">
                  <button
                    type="button"
                    onClick={() => navigate("/appointment")}
                    className="px-4 py-2 ml-3 text-sm font-medium text-gray-100 border border-gray-300 rounded-md shadow-sm bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                  >
                    Create another appointment
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default BookedAppointment;
