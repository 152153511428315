import React, { useContext, useEffect, useState } from "react";
import AddMedicine from "./AddMedicine";
import Modal from "../../components/Modal";
import { exportMedicines, getMedicines } from "../../apis/medicine";
import { Medicine } from "../../interfaces/Medicine";
import { Pagination, PaginationProps } from "antd";
import Filters from "../../components/Filters";
import { Filter } from "../../interfaces/Filter";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { LanguageContext } from "../../providers/LanguageProvider";
import { format } from "date-fns";
const MedicineList = () => {
  const [editableId, setEditableId] = useState(null);
  const [meta, setMeta] = useState<any>();
  const [isMedicineModalOpen, setMedicineModalVisibility] =
    useState<boolean>(false);
  const { translate: t } = useContext(LanguageContext);

  const [medicines, setMedicines] = useState<Array<Medicine>>([
    {
      name: "",
      molecule: "",
      companyId: 0,
      power: "",
      form: "",
    },
  ]);

  const navigate = useNavigate();
  const { search } = useLocation();

  const medicineFilters: Array<Filter> = [
    {
      label: "Company Id",
      key: "company_id",
      value: "",
      component: "input",
    },
    {
      label: "Power",
      key: "power",
      value: "",
      component: "input",
    },
    {
      label: "Form",
      key: "form",
      value: "",
      component: "input",
    },
  ];

  const [filters] = useState<Array<Filter>>(medicineFilters);
  const [isClearFilterList] = useState(false);
  const [, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (search) {
      const filters = JSON.parse(
        '{"' +
          decodeURI(search.substring(1))
            .replace(/\+/g, " ")
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"') +
          '"}'
      );
      fetchMedicines({ per_page: 10, ...filters });
    } else {
      fetchMedicines({ per_page: 10 });
    }
  }, [search]);

  const handleFilters = (params: any) => {
    if (params.length > 0) {
      let updatedQueryParams = new URLSearchParams();
      params.forEach((i: any) => {
        updatedQueryParams.set(i.key, i.value);
      });
      setSearchParams(updatedQueryParams.toString());
    } else {
      navigate(`/medicines`);
    }
  };

  const handleCloseModal = () => {
    setEditableId(null);
    setMedicineModalVisibility(false);
    fetchMedicines();
  };

  const fetchMedicines = (params = {}) => {
    getMedicines(params).then((res: any) => {
      setMedicines(res.data);
      setMeta(res.meta);
    });
  };

  const handleEditMedicine = (payload: any) => {
    setEditableId(payload.id);
    setMedicineModalVisibility(true);
  };

  //use per_page
  const onPaginationChange: PaginationProps["onChange"] = (page, pageSize) => {
    fetchMedicines({ page, per_page: pageSize });
  };

  const downloadMedicinesList = () => {
    if (search) {
      const filters = JSON.parse(
        '{"' +
          decodeURI(search.substring(1))
            .replace(/\+/g, " ")
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"') +
          '"}'
      );
      exportMedicinesList({ ...filters });
    } else {
      exportMedicinesList({});
    }
  };

  const exportMedicinesList = (params: any) => {
    exportMedicines(params).then((res: any) => {
      downloadFile(res.data);
    });
  };

  const downloadFile = (data: any) => {
    const blob = new Blob([data], { type: "application/vnd.ms-excel" });
    const url = window.URL.createObjectURL(blob);
    const downloadLink = document.createElement("a");
    downloadLink.href = url;
    const today = format(new Date(), "dd_MM_yyyy_hh_mm");
    downloadLink.download = "medicines-" + today + ".xlsx";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      {/* header for mobile screen */}
      <div className="flex lg:hidden">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            {t("medicines")}
          </h1>
        </div>
        <div className="flex flex-row-reverse w-full">
          <div>
            <button
              type="button"
              onClick={() => setMedicineModalVisibility(true)}
              className="block px-3 py-2 text-sm font-semibold text-center text-white rounded-md shadow-sm bg-primary hover:bg-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-hover"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 4.5v15m7.5-7.5h-15"
                />
              </svg>
            </button>
          </div>
          <div>
            <button
              type="button"
              onClick={() => downloadMedicinesList()}
              className="block px-3 py-2 mx-2 text-sm font-semibold text-center text-white capitalize rounded-md shadow-sm bg-primary hover:bg-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-hover"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 8.25H7.5a2.25 2.25 0 0 0-2.25 2.25v9a2.25 2.25 0 0 0 2.25 2.25h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25H15M9 12l3 3m0 0 3-3m-3 3V2.25"
                />
              </svg>
            </button>
          </div>
          <Filters
            clearFilterList={isClearFilterList}
            filters={filters}
            fetchList={(appliedFilters: any) => handleFilters(appliedFilters)}
          ></Filters>
        </div>
      </div>
      <div className="hidden lg:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            {t("medicines")}
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            {t("medicine_description")}
          </p>
        </div>
        <div className="mx-2 mt-4 sm:mt-0 sm:ml-16 sm:flex">
          <div className="mr-2">
            <Filters
              clearFilterList={isClearFilterList}
              filters={filters}
              fetchList={(appliedFilters: any) => handleFilters(appliedFilters)}
            ></Filters>
          </div>
          <button
            type="button"
            onClick={() => setMedicineModalVisibility(true)}
            className="block px-3 py-2 text-sm font-semibold text-center text-white rounded-md shadow-sm bg-primary hover:bg-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            {t("add_medicine")}
          </button>
          <button
            type="button"
            onClick={() => downloadMedicinesList()}
            className="block px-3 py-2 ml-2 text-sm font-semibold text-center text-white rounded-md shadow-sm bg-primary hover:bg-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            {t("export")}
          </button>
        </div>
      </div>
      <div className="flow-root mt-8">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      {t("name")}
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      {t("molecule")}
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      {t("company")}
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      {t("form")}
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      {t("power")}
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 capitalize"
                    >
                      {t("actions")}
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {medicines && medicines.length > 0 ? (
                    medicines.map((medicine) => (
                      <tr key={medicine.id}>
                        <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 capitalize whitespace-nowrap sm:pl-6">
                          {medicine.name}
                        </td>
                        <td className="px-3 py-4 text-sm text-gray-500 capitalize whitespace-nowrap">
                          {medicine.molecule}
                        </td>
                        <td className="px-3 py-4 text-sm text-gray-500 capitalize whitespace-nowrap">
                          {medicine.company ? medicine.company.name : ""}
                        </td>
                        <td className="px-3 py-4 text-sm text-gray-500 capitalize whitespace-nowrap">
                          {medicine.form}
                        </td>
                        <td className="px-3 py-4 text-sm text-gray-500 capitalize whitespace-nowrap">
                          {medicine.power}
                        </td>

                        <td className="relative py-4 pl-3 pr-4 text-sm font-medium text-left whitespace-nowrap sm:pr-6">
                          <button
                            onClick={() => handleEditMedicine(medicine)}
                            className="block px-3 py-2 text-sm font-semibold text-center text-white rounded-md shadow-sm bg-primary hover:bg-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          >
                            {t("edit")}
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr className="px-4 py-2 bg-white ">
                      <td
                        colSpan={6}
                        className="py-4 pl-4 pr-3 text-sm font-medium text-center text-gray-900 sm:pl-6"
                      >
                        {t("Record Not Found")}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="flex justify-end pt-5">
              <Pagination
                defaultCurrent={1}
                defaultPageSize={10}
                total={meta?.total}
                onChange={onPaginationChange}
              />
            </div>
          </div>
        </div>
      </div>
      {isMedicineModalOpen && (
        <Modal
          modalContent={
            <AddMedicine
              editableId={editableId}
              closeModal={() => handleCloseModal()}
            />
          }
        />
      )}
    </div>
  );
};

export default MedicineList;
