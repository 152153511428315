import { Select } from "antd";
import React, { useEffect, useState } from "react";
import { getMedicines } from "../../apis/medicine";
import { getServices } from "../../apis/service";
import {
  changeVisitData,
  selectVisit,
  UpdateVisitField,
} from "../../features/visit/visitSlice";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { Medicine } from "../../interfaces/Medicine";
import { Service } from "../../interfaces/Service";
import { Visit, VisitMedicines, VisitService } from "../../interfaces/Visit";
import { Editor, EditorState } from "react-draft-wysiwyg";
import { convertToRaw } from "draft-js";
import Modal from "../../components/Modal";
import Prescription from "./Prescription";
const forms = [
  {
    name: "Tablet",
    value: "tablet",
  },

  {
    name: "Capsule",
    value: "capsule",
  },

  {
    name: "Syrup",
    value: "syrup",
  },

  {
    name: "Drops",
    value: "drops",
  },

  {
    name: "Creame",
    value: "creame",
  },

  {
    name: "Lotion",
    value: "lotion",
  },

  {
    name: "gel",
    value: "gel",
  },

  {
    name: "Powder",
    value: "powder",
  },

  {
    name: "Soap",
    value: "soap",
  },
  {
    name: "Inhaler",
    value: "inhaler",
  },
];
const Treatment: React.FC<{
  preview?: boolean;
}> = ({ preview = false }) => {
  const visit = useAppSelector(selectVisit);
  const dispatch = useAppDispatch();
  const [medicines, setMedicines] = useState<Array<Medicine>>([]);
  const [services, setServices] = useState<Array<Service>>([]);
  const [selectedServices, setSelectedServices] = useState<Array<any>>([]);
  const [selectedMedicines, setSelectedMedicines] = useState<Array<any>>([]);
  const [isPrescriptionVisible, setIsPrescriptionVisible] = useState(false);

  const [AdviceEditorState, setAdviceEditorState] = useState(
    EditorState?.createEmpty()
  );

  const mealTypes = [
    {
      name: "Before Meal",
      value: "before_meal",
    },
    {
      name: "After Meal",
      value: "after_meal",
    },
  ];

  const durations = [
    {
      name: "Days",
      value: "days",
    },
    {
      name: "Weeks",
      value: "weeks",
    },
    {
      name: "Months",
      value: "months",
    },
  ];
  const frequencies = [
    {
      name: "Daily",
      value: "daily",
    },
    {
      name: "Weekly",
      value: "weekly",
    },
    {
      name: "Alternate Days",
      value: "alternate days",
    },
  ];

  const powerUnits = [
    {
      name: "MG",
      value: "MG",
    },
    {
      name: "IU",
      value: "IU",
    },
    {
      name: "MCG",
      value: "MCG",
    },
  ];

  useEffect(() => {
    fetchMedicines();
    fetchServices();
  }, []);

  useEffect(() => {
    if (visit && visit.services.length > 0) {
      const selectedServices = visit.services.map((i) => i.id);
      setSelectedServices(selectedServices);
    }

    if (visit && visit.medicines.length > 0) {
      const selectedMedicines = visit.medicines.map((i) => i.id);
      setSelectedMedicines(selectedMedicines);
    }
  }, [visit]);

  const updateField = (fieldName: keyof Visit, value: any) => {
    const payload: UpdateVisitField = { fieldName, value };
    dispatch(changeVisitData(payload));
  };

  const fetchMedicines = () => {
    getMedicines({}).then((res: any) => {
      setMedicines(res.data);
    });
  };

  const fetchServices = () => {
    getServices({}).then((res: any) => {
      setServices(res.data.data);
    });
  };

  const handleMedicineChange = (value: any) => {
    let visitMedicines: Array<VisitMedicines> = [];
    const latestElement = value.slice(-1)[0];
    const [ifExists] = selectedMedicines.filter((i) => i === latestElement);
    if (ifExists) {
      setSelectedMedicines(value);
    } else {
      setSelectedMedicines([...selectedMedicines, latestElement]);
    }
    value.forEach((i: number) => {
      const [medicine] = medicines.filter((medicine) => medicine.id === i);
      if (medicine && medicine.id) {
        visitMedicines.push({
          id: medicine.id,
          name: medicine.name,
          instructions: {
            name: medicine.name,
            molecule: medicine.molecule,
            form: medicine.form,
            morning: 0,
            afternoon: 0,
            evening: 0,
            night: 0,
            meal: "before_meal",
            note: "",
            quantity: 0,
            frequency: "daily",
            duration: "",
            power: "",
            unit: "",
            dosage: "",
            durationPeriod: "days",
          },
        });
      }
    });
    updateField("medicines", visitMedicines);
  };

  const handleServiceChange = (value: any) => {
    let visitServices: Array<VisitService> = [];
    const latestElement = value.slice(-1)[0];
    const [ifExists] = selectedServices.filter((i) => i === latestElement);
    if (ifExists) {
      setSelectedServices(value);
    } else {
      setSelectedServices([...selectedServices, latestElement]);
    }
    value.forEach((i: number) => {
      const [service] = services.filter((service) => service.id === i);
      if (service && service.id) {
        visitServices.push({
          id: service.id,
          fee: service.fee,
          name: service.name,
          quantity: 1,
        });
      }
    });
    updateField("services", visitServices);
  };

  const handleChangeInstructions = (value: any, key: string, medicine: any) => {
    const visitMedicines = visit.medicines.map((i) => {
      if (i.id === medicine.id) {
        return {
          id: i.id,
          instructions: {
            ...i.instructions,
            [key]: value,
          },
        };
      } else {
        return { ...i };
      }
    });
    updateField("medicines", visitMedicines);
  };

  const onEditorStateChange = (
    editorState: EditorState,
    field: keyof Visit,
    setState: React.Dispatch<React.SetStateAction<EditorState>>
  ) => {
    setState(editorState);
    const contentState = editorState.getCurrentContent();
    const contentStateString = JSON.stringify(convertToRaw(contentState));
    updateField(field, contentStateString);
  };

  return (
    <div>
      <div className="flex justify-end p-4">
        <button
          onClick={() => setIsPrescriptionVisible(true)}
          className="px-3 py-2 text-sm text-white rounded-md bg-primary"
        >
          Prescription View
        </button>
      </div>
      <div className="grid gap-4 p-4 lg:grid-cols-2">
        <div className="block">
          <span className="text-sm font-medium text-gray-700">
            Select Medicine
          </span>
          <Select
            mode="multiple"
            style={{ width: "100%" }}
            placeholder="Select medicines"
            onChange={handleMedicineChange}
            value={selectedMedicines}
            options={medicines.map((i) => ({ value: i.id, label: i.name }))}
          />
        </div>
        <div className="grid grid-cols-1 gap-4">
          <div className="block">
            <span className="text-sm font-medium text-gray-700">
              Select Service
            </span>
            <Select
              mode="multiple"
              style={{ width: "100%" }}
              placeholder="Select services"
              onChange={handleServiceChange}
              value={selectedServices}
              options={services.map((i) => ({ value: i.id, label: i.name }))}
            />
          </div>

          <div className="block">
            <span className="text-sm font-medium text-gray-700">Advice</span>

            <Editor
              editorState={AdviceEditorState}
              toolbarClassName="border border-gray-300 rounded-md"
              wrapperClassName="h-auto"
              editorClassName="h-full border border-gray-300 rounded-md p-2 "
              onEditorStateChange={(editorState) =>
                onEditorStateChange(editorState, "advice", setAdviceEditorState)
              }
              toolbar={{
                options: ["inline", "list", "blockType"],
                inline: {
                  options: ["bold", "italic", "underline"],
                },
              }}
            />
          </div>
        </div>
      </div>

      <div className="mt-2 bg-gray-100 outline-none">
        {visit.medicines &&
          visit.medicines.map((item, index) => (
            <div key={item.id} className="p-2">
              <div className="items-center justify-between text-gray-600 transition duration-500 cursor-pointer ease">
                <div className="flex flex-row w-full p-2 transition duration-500 bg-blue-300 rounded-md basis-1/4 group-focus:text-black ease">
                  <span className="w-full font-semibold capitalize">
                    {index + 1}. {item?.instructions?.name}
                  </span>

                  <span className="flex justify-end items-center px-3 py-0.5 rounded-md text-sm font-medium bg-gray-100 text-gray-800">
                    Remove
                  </span>
                </div>
              </div>
              <div className="p-2 duration-500 bg-gray-100 ease">
                <div className="grid grid-cols-2 gap-4 lg:grid-cols-4">
                  <span className="text-sm font-medium text-gray-700">
                    Form
                    <div className="mt-1">
                      <Select
                        style={{ width: "100%" }}
                        onChange={(value) =>
                          handleChangeInstructions(value, "form", item)
                        }
                        value={item?.instructions?.form}
                        options={forms}
                      />
                    </div>
                  </span>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Duration
                    </label>
                    <div className="relative mt-1 rounded-md shadow-sm">
                      <input
                        type="text"
                        id="duration"
                        value={item?.instructions?.duration}
                        onChange={(e) =>
                          handleChangeInstructions(
                            e.target.value,
                            "duration",
                            item
                          )
                        }
                        className="block w-full py-1 pr-20 text-gray-900 border-0 rounded-md ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                      <div className="absolute inset-y-0 right-0 flex items-center">
                        <label className="sr-only">Duration</label>
                        <select
                          id="frequency"
                          value={item?.instructions?.durationPeriod}
                          onChange={(e) =>
                            handleChangeInstructions(
                              e.target.value,
                              "durationPeriod",
                              item
                            )
                          }
                          className="h-full py-0 pl-2 text-gray-500 bg-transparent border-0 rounded-md pr-7 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                        >
                          {durations.map((i) => (
                            <option
                              key={i.name}
                              value={i.value}
                              selected={
                                i.value === item.instructions.durationPeriod
                              }
                            >
                              {i.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Frequency
                    </label>
                    <div className="relative mt-1 rounded-md shadow-sm">
                      <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <span className="text-gray-500 sm:text-sm"> </span>
                      </div>
                      <div>
                        <div className="mt-1">
                          <Select
                            style={{ width: "100%" }}
                            onChange={(value) =>
                              handleChangeInstructions(value, "frequency", item)
                            }
                            value={item?.instructions?.frequency}
                            options={frequencies}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Power
                    </label>
                    <div className="relative mt-1 rounded-md shadow-sm">
                      <input
                        type="text"
                        id="power"
                        value={item?.instructions?.power}
                        onChange={(e) =>
                          handleChangeInstructions(
                            e.target.value,
                            "power",
                            item
                          )
                        }
                        className="block w-full py-1 pr-20 text-gray-900 border-0 rounded-md ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                      <div className="absolute inset-y-0 right-0 flex items-center">
                        <label className="sr-only">Power</label>
                        <select
                          id="unit"
                          value={item?.instructions?.unit}
                          onChange={(e) =>
                            handleChangeInstructions(
                              e.target.value,
                              "unit",
                              item
                            )
                          }
                          className="h-full py-0 pl-2 text-gray-500 bg-transparent border-0 rounded-md pr-7 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                        >
                          {powerUnits.map((i) => (
                            <option key={i.name} value={i.value}>
                              {i.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="grid gap-4 mt-4 lg:grid-cols-4">
                  <fieldset>
                    <legend className="block text-sm font-medium text-gray-700">
                      Dosage
                    </legend>
                    <div className="mt-1 -space-y-px bg-white shadow-sm">
                      <div className="flex -space-x-px">
                        <div className="flex-1 min-w-0">
                          <label className="sr-only">Morning</label>
                          <input
                            name="card-cvc"
                            id="card-cvc"
                            type="number"
                            onChange={(e) =>
                              handleChangeInstructions(
                                e.target.value,
                                "morning",
                                item
                              )
                            }
                            className="relative block w-full bg-transparent border-gray-300 rounded-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                            value={item?.instructions?.morning}
                            placeholder="Morning"
                          />
                        </div>
                        <div className="flex-1 min-w-0">
                          <label className="sr-only">AfterNoon</label>
                          <input
                            name="card-cvc"
                            id="card-cvc"
                            type="number"
                            className="relative block w-full bg-transparent border-gray-300 rounded-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                            value={item?.instructions?.afternoon}
                            placeholder="AfterNoon"
                            onChange={(e) =>
                              handleChangeInstructions(
                                e.target.value,
                                "afternoon",
                                item
                              )
                            }
                          />
                        </div>
                        <div className="flex-1 min-w-0">
                          <label className="sr-only">Night</label>
                          <input
                            name="card-cvc"
                            id="card-cvc"
                            type="number"
                            className="relative block w-full bg-transparent border-gray-300 rounded-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                            value={item?.instructions?.night}
                            placeholder="Night"
                            onChange={(e) =>
                              handleChangeInstructions(
                                e.target.value,
                                "night",
                                item
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </fieldset>

                  <div className="block">
                    <span className="text-sm font-medium text-gray-900">
                      Meal
                    </span>
                    <div className="mt-2">
                      <label className="inline-flex items-center mr-2">
                        {mealTypes.map((mealType) => (
                          <div
                            key={mealType.value}
                            className="flex items-center"
                          >
                            <input
                              // name={"notification-method"}
                              name={`notification-method-${item.id}`}
                              value={item?.instructions?.meal}
                              type="radio"
                              checked={
                                mealType.value === item?.instructions?.meal
                              }
                              className="w-4 h-4 ml-2 text-indigo-600 border-gray-300 focus:ring-indigo-600"
                              onChange={(e) =>
                                handleChangeInstructions(
                                  mealType.value,
                                  "meal",
                                  item
                                )
                              }
                            />
                            <label className="block ml-3 text-sm font-medium leading-6 text-gray-900">
                              {mealType.name}
                            </label>
                          </div>
                        ))}
                      </label>
                    </div>
                  </div>

                  <div className="block">
                    <span className="text-sm font-medium text-black cursor-pointer">
                      Note
                    </span>
                    <input
                      type="text"
                      onChange={(e) =>
                        handleChangeInstructions(e.target.value, "note", item)
                      }
                      value={item?.instructions?.note}
                      className="w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 sm:text-sm"
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
      {isPrescriptionVisible && (
        <Modal
          width="max-w-2xl"
          title="Prescription Preview"
          onClose={() => setIsPrescriptionVisible(false)}
          modalContent={
            <Prescription
              prescription={{
                clinicName: "something",
                user: {
                  name: "sanket",
                },
                patient: {
                  name: "something",
                  gender: "male",
                  age: 30,
                  phone: 7030984869,
                },
              }}
              preview
              medicines={visit.medicines}
              advice={visit.advice}
            />
          }
        />
      )}
    </div>
  );
};

export default Treatment;
