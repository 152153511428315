import { Select } from "antd";
import { useContext, useEffect, useState } from "react";
import {
  getSettings,
  updateDefaultDoctor,
  updateSettings,
} from "../../apis/setting";
import { Setting } from "../../interfaces/Setting";
import { useNotification } from "../../NotificationContext";
import { getTranslations } from "../../apis/auth";
import Modal from "../../components/Modal";
import AddTranslation from "./AddTranslation";
import { getUsers } from "../../apis/user";
import { LanguageContext } from "../../providers/LanguageProvider";
// import { userAgent } from "next/server";

const SettingsList = () => {
  const [settings, setSettings] = useState<Array<Setting>>([]);
  const [editableField, setEditableField] = useState<string>();
  const [isValid, setIsValid] = useState(true);
  const { showNotification } = useNotification();
  const [feeModuleActive, setFeeModuleActive] = useState(true);
  const [backupModuleActive, setBackupModuleActive] = useState(false);
  const [reportModuleActive, setReportModuleActive] = useState(false);
  const [tabs] = useState(["General", "Translations"]);
  const [selectedTab, setSelectedTab] = useState("General");
  const token = window.localStorage.getItem("token");
  const [language, setLanguage] = useState<string>("en");
  const [translations, setTranslations] = useState<any>([]);
  const [isTranslationModalOpen, setTranslationModalVisibility] =
    useState<boolean>(false);
  const [doctorOnPrescription, setDoctorOnPrescription] =
    useState<any>("logged_in_user");
  const [selectedDefaultDoctor, setSelectedDefaultDoctor] = useState<any>(null);

  const [doctors, setDoctors] = useState<Array<any>>([]);
  const [editableId, setEditableId] = useState(null);

  const Option = Select;
  const { translate: t } = useContext(LanguageContext);

  const languageOptions = [
    { name: "English", value: "en" },
    { name: "Hindi", value: "hn" },
    { name: "Marathi", value: "mr" },
  ];

  const prescriptionOptions = [
    { id: "logged_in_user", title: "Logged In User" },
    { id: "default", title: "Default Doctor" },
  ];

  const [location, setLocation] = useState("");

  const [isHovered, setIsHovered] = useState(false);
  const [copied, setCopied] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
    setCopied(false); // reset the copied state
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopied(true);
    });
  };

  const toggleFeeModule = () => {
    setFeeModuleActive(!feeModuleActive);
  };

  const toggleBackupModule = () => {
    setBackupModuleActive(!backupModuleActive);
  };

  const toggleReportModule = () => {
    setReportModuleActive(!reportModuleActive);
  };

  useEffect(() => {
    fetchSettings();
    fetchTranslations(language);
  }, [language]);

  useEffect(() => {
    fetchDoctors({ role: "doctor" });
  }, []);

  const fetchSettings = () => {
    getSettings().then((res: any) => {
      const [result] = res.data.data.filter(
        (i: any) => i.code === "doctor_on_prescription"
      );

      if (result && result.value !== "logged_in_user") {
        setDoctorOnPrescription("default");
        setSelectedDefaultDoctor(result.value);
      }
      setSettings(res.data.data);
    });
  };
  const handleCloseModal = () => {
    setTranslationModalVisibility(false);
    fetchTranslations(language);
    setEditableId(null);
  };

  const updateSettingsItem = (item: Setting, value: string) => {
    const updatedSettings = settings.map((setting) => {
      if (setting.id === item.id) {
        setting.value = value;
      }
      return setting;
    });
    setSettings(updatedSettings);
  };

  const fetchTranslations = async (locale: any) => {
    try {
      if (token) {
        const res: any = await getTranslations(locale);
        setTranslations(res.data);
      }
    } catch (error) {
      console.error("Error fetching translations:", error);
    }
  };

  const handleLanguageChange = (language: string) => {
    setLanguage(language);
  };

  const updateSettingsData = (item: Setting) => {
    if (!item.value) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
    if (isValid) {
      setEditableField("");
      updateSettings({
        id: item.id,
        ...item,
      }).then((res) => {
        showNotification(
          "success",
          "Setting Updated successfully",
          "",
          "topRight"
        );
      });

      fetchSettings();
    }
  };

  const changeTab = (value: any) => {
    setSelectedTab(value);
  };

  const fetchDoctors = (params = {}) => {
    getUsers(params).then((res: any) => {
      setDoctors(res.data);
    });
  };

  const updateDefaultDoctorSetting = (value: any) => {
    updateDefaultDoctor({ value }).then((res: any) => {
      showNotification(
        "success",
        "Doctor on prescription setting updated successfully",
        "",
        "topRight"
      );
      fetchSettings();
    });
  };

  const handlePrescriptionDoctorChange = (value: string) => {
    setDoctorOnPrescription(value);
    if (value === "logged_in_user") {
      updateDefaultDoctorSetting(value);
    }
  };

  const handleDefaultDoctorChange = (value: any) => {
    setSelectedDefaultDoctor(value);
    updateDefaultDoctorSetting(value);
  };

  const doctorOnPrescriptionRadio = (
    <div className="mt-2 space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
      {prescriptionOptions.map((option) => (
        <div key={option.id} className="flex items-center">
          <input
            id={option.id}
            type="radio"
            name="prescriptionDoctor"
            value={option.id}
            checked={doctorOnPrescription === option.id}
            className="text-primary focus:ring-primary"
            onChange={(e) => handlePrescriptionDoctorChange(e.target.value)}
          />
          <label className="ml-2 text-sm font-medium text-gray-700">
            {option.title}
          </label>
        </div>
      ))}
    </div>
  );

  const handleEditTranslation = (payload: any) => {
    setEditableId(payload.id);
    setTranslationModalVisibility(true);
  };

  const renderItemContent = (item: Setting) => {
    if (item.code === "doctor_on_prescription") {
      return <div className="mt-1">{doctorOnPrescriptionRadio}</div>;
    }

    if (editableField === item.code) {
      return (
        <input
          name="code"
          type="text"
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          placeholder=""
          value={item?.value}
          onChange={(e) => updateSettingsItem(item, e.target.value)}
          required
        />
      );
    }

    if (item.code === "location") {
      return (
        <div
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={() => copyToClipboard(item.value)}
          className="relative w-2/3 p-2 break-words bg-gray-200 rounded-md text-wrap"
        >
          <span className="cursor-pointer">{item.value}</span>
          {/* Hover text */}
          {isHovered && !copied && (
            <div className="absolute left-0 p-1 mt-1 text-xs text-gray-600 bg-gray-100 border rounded shadow-md top-full">
              Click to copy
            </div>
          )}

          {/* Copied text */}
          {copied && (
            <div className="absolute left-0 p-1 mt-1 text-xs text-green-600 bg-gray-100 border rounded shadow-md top-full">
              Copied!
            </div>
          )}
        </div>
      );
    }

    return <span className="capitalize ">{item.value}</span>;
  };

  const renderItem = (item: Setting) => {
    return item.value ? (
      renderItemContent(item)
    ) : (
      <div className="mt-1 text-sm text-red-500">Required</div>
    );
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="pt-2 pb-16">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            {t("settings")}
          </h1>
        </div>
        <div className="py-6">
          <div>
            <div className="hidden lg:block">
              <div className="border-b border-gray-200">
                <nav className="flex -mb-px space-x-8">
                  {tabs.map((item) => (
                    <button
                      key={item}
                      onClick={() => changeTab(item)}
                      className={`${
                        selectedTab === item
                          ? "border-primary text-primary whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                          : "text-gray-600 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                      }`}
                    >
                      {item}
                    </button>
                  ))}
                </nav>
              </div>
            </div>
          </div>
          {selectedTab === "General" && (
            <div className="py-6">
              <div>
                <div className="mt-6 divide-y divide-gray-200">
                  <div className="space-y-1">
                    <h3 className="text-sm font-medium leading-6 text-gray-900">
                      <b> {t("organization_details")}</b>
                    </h3>
                    <p className="max-w-2xl text-sm text-gray-500">
                      This information will be displayed in prescription.
                    </p>
                  </div>
                  <div className="px-4 mt-2 bg-white divide-y divide-gray-200">
                    {settings.map((item) => (
                      <div key={item.code} className="mt-2">
                        <dl className="divide-y divide-gray-200">
                          <div className="grid grid-cols-3 py-4 sm:gap-4">
                            <dt className="text-sm font-medium text-gray-500 capitalize">
                              {item.name}
                            </dt>
                            <dd className="flex items-center justify-between col-span-2 mt-1 text-sm text-gray-900 sm:mt-0">
                              {renderItem(item)}

                              {item.code !== "doctor_on_prescription" && (
                                <span className="flex-shrink-0 ml-4">
                                  {editableField !== item.code ? (
                                    <button
                                      type="button"
                                      className="block px-3 py-2 text-sm font-semibold text-center text-white rounded-md shadow-sm bg-primary hover:bg-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                      onClick={() =>
                                        setEditableField(item.code)
                                      }
                                    >
                                      {t("edit")}
                                    </button>
                                  ) : (
                                    <button
                                      type="button"
                                      className="block px-3 py-2 text-sm font-semibold text-center text-white rounded-md shadow-sm bg-primary hover:bg-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                      onClick={() => updateSettingsData(item)}
                                    >
                                      {t("update")}
                                    </button>
                                  )}
                                </span>
                              )}
                            </dd>
                          </div>
                        </dl>
                      </div>
                    ))}

                    {doctorOnPrescription === "default" &&
                      doctors.length > 0 && (
                        <dl className="divide-y divide-gray-200">
                          <div className="py-4 capitalize sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                            <dt className="ml-6 text-sm font-medium text-gray-500">
                              Select doctor
                            </dt>
                            <dd className="flex items-center mt-1 text-sm text-gray-900 sm:mt-0">
                              <div className="mt-2">
                                <select
                                  className="w-full max-w-xl pt-1 pb-1 border-gray-300 rounded-lg sm:text-sm"
                                  name="selectedDoctor"
                                  id=""
                                  value={selectedDefaultDoctor}
                                  onChange={(e) =>
                                    handleDefaultDoctorChange(e.target.value)
                                  }
                                >
                                  {doctors.map((doctor: any) => (
                                    <option key={doctor.id} value={doctor.id}>
                                      {doctor.firstName + " " + doctor.lastName}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </dd>
                          </div>
                        </dl>
                      )}
                  </div>
                </div>

                <div className="mt-10 divide-y divide-gray-200">
                  <div className="space-y-1">
                    <h3 className="text-sm font-medium leading-6 text-gray-900">
                      <b> Module Integration</b>
                    </h3>
                    <p className="max-w-2xl text-sm text-gray-500">
                      Manage how information is displayed on your account.
                    </p>
                  </div>
                  <div className="mt-6">
                    <dl className="divide-y divide-gray-200">
                      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5">
                        <dt
                          className="text-sm font-medium text-gray-500"
                          id="timezone-option-label"
                        >
                          Fee Module
                        </dt>
                        <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          <button
                            type="button"
                            className={`bg-gray-200 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:ml-auto ${
                              feeModuleActive ? "bg-purple-600" : ""
                            }`}
                            role="switch"
                            aria-checked="true"
                            aria-labelledby="timezone-option-label"
                            onClick={toggleFeeModule}
                          >
                            <span
                              aria-hidden="true"
                              className="inline-block w-5 h-5 transition duration-200 ease-in-out transform translate-x-0 bg-white rounded-full shadow ring-0"
                            ></span>
                          </button>
                        </dd>
                      </div>
                      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-b sm:border-gray-200">
                        <dt
                          className="text-sm font-medium text-gray-500"
                          id="auto-update-option-label"
                        >
                          Backup Module
                        </dt>
                        <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          <button
                            type="button"
                            className={`bg-gray-200 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:ml-auto ${
                              backupModuleActive ? "bg-purple-600" : ""
                            }`}
                            role="switch"
                            aria-checked="false"
                            aria-labelledby="auto-update-option-label"
                            onClick={toggleBackupModule}
                          >
                            <span
                              aria-hidden="true"
                              className="inline-block w-5 h-5 transition duration-200 ease-in-out transform translate-x-0 bg-white rounded-full shadow ring-0"
                            ></span>
                          </button>
                        </dd>
                      </div>

                      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-b sm:border-gray-200">
                        <dt
                          className="text-sm font-medium text-gray-500"
                          id="auto-update-option-label"
                        >
                          Report Module
                        </dt>
                        <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          <button
                            type="button"
                            className={`bg-gray-200 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:ml-auto ${
                              reportModuleActive ? "bg-purple-600" : ""
                            }`}
                            role="switch"
                            aria-checked="false"
                            onClick={toggleReportModule}
                          >
                            <span
                              aria-hidden="true"
                              className="inline-block w-5 h-5 transition duration-200 ease-in-out transform translate-x-0 bg-white rounded-full shadow pointer-events-none ring-0"
                            ></span>
                          </button>
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          )}
          {selectedTab === "Translations" && (
            <div className="block">
              <div className="mt-10 divide-y divide-gray-200">
                <div className="space-y-1">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    <b>Select Language</b>
                  </h3>

                  <div className="mt-1">
                    <Select
                      style={{ width: "20%" }}
                      onChange={handleLanguageChange}
                      value={language}
                    >
                      {languageOptions.map((item: any) => (
                        <Option key={item.name} value={item.value}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </div>

                  <div className="">
                    <button
                      type="button"
                      onClick={() => setTranslationModalVisibility(true)}
                      className="block float-right px-3 py-2 text-sm font-semibold text-center text-white rounded-md shadow-sm bg-primary hover:bg-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      {t("add_new_translations")}
                    </button>
                  </div>
                </div>
              </div>
              <div className="mt-6 divide-y divide-gray-200">
                <div className="space-y-1">
                  <h3 className="text-sm font-medium leading-6 text-gray-900">
                    <b> Translation Details</b>
                  </h3>
                </div>

                <div className="mt-2 bg-white divide-y divide-gray-200">
                  {translations.map((item: any) => (
                    <div key={item.id} className="mt-2">
                      <dl className="divide-y divide-gray-200">
                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                          <dt className="ml-6 text-sm font-medium text-gray-500">
                            {item.key}
                          </dt>
                          <dt className="ml-6 text-sm font-medium text-gray-500">
                            {item.value}
                          </dt>

                          <div className="relative py-4 pl-3 pr-4 text-sm font-medium text-left whitespace-nowrap sm:pr-6">
                            <button
                              onClick={() => handleEditTranslation(item)}
                              className="block px-3 py-2 text-sm font-semibold text-center text-white rounded-md shadow-sm bg-primary hover:bg-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                              {t("edit")}
                            </button>
                          </div>
                        </div>
                      </dl>
                    </div>
                  ))}
                </div>
              </div>
              {isTranslationModalOpen && (
                <Modal
                  modalContent={
                    <AddTranslation
                      editableId={editableId}
                      language={language}
                      closeModal={() => handleCloseModal()}
                    />
                  }
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default SettingsList;
