import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BarChart: React.FC<{
  data: ChartData<"bar", (number | [number, number] | null)[], unknown>;
}> = ({ data }) => {
  console.log(data);

  const options = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };
  return (
    <div className="flex flex-col items-center">
      <div className="">
        <span>Last 6 Months' Visits</span>
      </div>
      <Bar data={data} options={options} />
    </div>
  );
};

export default BarChart;
