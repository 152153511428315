import api from "../middlware/api";
import { camelToSnake, snakeCase } from "../utils/CamelToSnake";
import { BASE_URL } from "../constants";

export const getUsers = (params: any) => {
  return new Promise((resolve, reject) => {
    let query = new URLSearchParams();
    for (let key in params) {
      query.set(key, params[key]);
    }
    api
      .get(`${BASE_URL}/api/v1/users?${query.toString()}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getUserRoles = () => {
  return new Promise((resolve, reject) => {
    api
      .get(`${BASE_URL}/api/v1/roles`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const saveUser = (payload: any) => {
  return new Promise((resolve, reject) => {
    api
      .post(`${BASE_URL}/api/v1/users`, camelToSnake(payload))
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

//get single user
export const getUser = (payload: any) => {
  return new Promise((resolve, reject) => {
    api
      .get(`${BASE_URL}/api/v1/users/${payload}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateUser = (payload: any) => {
  return new Promise((resolve, reject) => {
    api
      .put(`${BASE_URL}/api/v1/users/${payload.id}`, camelToSnake(payload))
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateUserData = (payload: any) => {
  return new Promise((resolve, reject) => {
    api.post(`${BASE_URL}/api/v1/users/${payload.id}`, camelToSnake(payload)).then(res => {
      resolve(res.data)
    }).catch(err => {
      reject(err)
    });
  });
}

export const exportUsers= (params: any) => {
  return new Promise((resolve, reject) => {
    let query = new URLSearchParams();
    for (let key in params) {
      query.set(key, params[key]);
    }
    api
      .get(`${BASE_URL}/api/v1/export/users?${query.toString()}`,{
        headers:{
          "Content-Type":"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
        responseType:"blob"
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getClinicUsers = () => {
  return new Promise((resolve, reject) => {
    api
      .get(`${BASE_URL}/api/v1/clinic-users`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};